export enum Decision {
  APPROVED = "Approved",
  PENDING = "Pending",
  REJECTED = "Rejected",
}

export enum ApprovalType {
  GO_NO_GO = "Go/No Go",
  PROPOSAL = "Proposal",
}

export type Approval = {
  id: number;
  opportunity?: Opportunity;
  opportunity_id: number;
  updated_at: number;
  user: User;
  user_id: string;
  decision: Decision;
  type: ApprovalType;
};

export type ApprovalRequest = {
  additional_approver_user_ids?: string[];
  opportunity_id?: number;
  success?: boolean;
  type?: ApprovalType;
};

export type ClientSector = {
  id: number;
  title: string;
};

export type ClientType = {
  id: number;
  title: string;
};

export type Client = {
  id: number;
  client_sector: ClientSector;
  client_sector_id: number;
  client_type: ClientType;
  client_type_id: number;
  contacts?: Contact[];
  organization_name: string;
};

export type Contact = {
  id: number;
  client: Client;
  client_id: number;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  email: string;
  first_name: string;
  full_name: string;
  is_default: boolean;
  last_name: string;
  phone?: string;
  state?: string;
  title?: string;
  zip_code?: string;
};

export type ClientContactReport = {
  id: number;
  created_by: string;
  client: Client;
  client_id: number;
  contact: Contact;
  contact_id: number;
  opportunity?: Opportunity;
  opportunity_id?: number;
  notes: string;
  occurred_at: string;
};

export type Config = {
  key?: string;
  success?: boolean;
  value?: Record<string, unknown>;
};

export type Email = {
  content?: string;
  recipientEmailAddresses?: string[];
  subject?: string;
  success?: boolean;
};

export type EvaluationPointOption = {
  id: number;
  evaluation_point_id: number;
  description: string;
  title: string;
};

export type EvaluationPoint = {
  id: number;
  evaluation_point_options: EvaluationPointOption[];
  description: string;
  title: string;
};

export type ContractStructure = {
  id: number;
  description: string;
  title: string;
};

export type ContractType = {
  id: number;
  title: string;
};

export type GoNoGo = {
  id: number;
  contract_structure: ContractStructure;
  contract_structure_id: number;
  contract_type: ContractType;
  contract_type_id: number;
  evaluation_point_option_ids: number[];
  opportunity: Opportunity;
  opportunity_id: number;
  responsibility: Responsibility;
  responsibility_id: number;
  proposal_due_date: string;
  estimated_labor_hours: number | null;
  win_probability: number;
};

export type Organization = {
  id: number;
  title: string;
};

export enum CostProposalType {
  BID = "Bid",
  RATES = "Rates",
  NONE = "None",
  OTHER = "Other",
}

export type Proposal = {
  id: number;
  opportunity: Opportunity;
  opportunity_id: number;
  accounting_number: string;
  bid_effective_multiplier: number;
  bid_net_revenue: number;
  bid_total: number;
  cost_proposal_type: CostProposalType;
  direct_labor_bid: number;
  direct_labor_cost: number;
  sub_odc_bid: number;
  sub_odc_cost: number;
};

export enum OpportunityStatus {
  AWARDED = "Awarded",
  DROPPED = "Dropped",
  GO_NO_GO = "Go/No Go",
  LOST = "Lost",
  PROPOSAL = "Proposal",
}

export type ServiceLine = {
  id: number;
  title: string;
};

export type ServiceType = {
  id: number;
  service_line: ServiceLine;
  service_line_id: number;
  title: string;
};

export type Opportunity = {
  id: number;
  approvals: Approval[];
  created_by: string;
  client: Client;
  client_id: number;
  go_no_gos: GoNoGo[];
  organization: Organization;
  organization_id: number;
  proposals: Proposal[];
  principal: User;
  principal_user_id: string;
  program_manager?: User;
  program_manager_user_id?: string;
  project_manager: User;
  project_manager_user_id: string;
  service_type: ServiceType;
  service_type_id: number;
  anticipated_award_date: string;
  anticipated_contract_amount: number;
  award_date?: string;
  description?: string;
  go_probability: number;
  is_confidential: boolean;
  status: OpportunityStatus;
  title: string;
};

export type RequiredApprover = {
  opportunity_id: number;
  user_id: string;
};

export type Responsibility = {
  id: number;
  title: string;
};

export enum ApplicationRole {
  ACCOUNTING = "Accounting",
  ADMIN = "Admin",
  CEO = "CEO",
  CFO = "CFO",
}

export type Role = {
  id: number;
  title: ApplicationRole;
};

export type User = {
  id: string;
  auth_provider_ids: Record<string, any>;
  full_name: string;
  roles: Role[];
  email: string;
};
