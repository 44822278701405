import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { Switch } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";

export function SwitchField(
  props: ComponentPropsWithoutRef<typeof Switch> & {
    label: string;
    name: string;
  }
) {
  const { label, name, required = false, ...rest } = props;
  const { control } = useFormContext();

  return (
    <FormField {...{ label, name, required }}>
      <Controller
        {...{ control, name }}
        render={({ field }) => {
          const { onChange, value, ...switchProps } = field;

          function handleCheckedChange(checked: boolean) {
            onChange({ target: { value: checked } });
          }

          return (
            <Switch
              {...rest}
              {...switchProps}
              checked={value}
              onCheckedChange={handleCheckedChange}
              size="2"
            />
          );
        }}
      />
    </FormField>
  );
}
