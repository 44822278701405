import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { Box, css, Paragraph } from "@modulz/design-system";

import type { FormEvent, InputHTMLAttributes } from "react";

const datalist = css({
  display: "flex",
  jc: "space-between",
  width: "100%",
});
const input = css({ width: "100%" });
const p = css({ my: "$2" });
const box = css({ width: "100%" });

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  required?: boolean;
  requiredMessage?: string;
};

export function PercentField(props: Props) {
  const {
    label,
    name,
    required = false,
    requiredMessage = `${label} is required`,
    ...inputProps
  } = props;
  const { control, register } = useFormContext();

  return (
    <FormField {...{ label, name, required }}>
      <Controller
        {...{ control, name }}
        render={({ field }) => {
          const value = Math.round(field.value * 100);

          function handleChange(event: FormEvent<HTMLInputElement>) {
            field.onChange(Number(event.currentTarget.value) / 100);
          }

          return (
            <>
              <Paragraph className={p()} size="2">
                {value}%
              </Paragraph>

              <Box className={box()}>
                <input
                  {...register(name, { valueAsNumber: true })}
                  {...inputProps}
                  {...field}
                  {...{ value }}
                  className={input()}
                  list="tickmarks"
                  max={100}
                  min={0}
                  onChange={handleChange}
                  step="5"
                  type="range"
                />

                <datalist className={datalist()} id="tickmarks">
                  <option value="0" label="0%"></option>
                  <option value="25" label="25%"></option>
                  <option value="50" label="50%"></option>
                  <option value="75" label="75%"></option>
                  <option value="100" label="100%"></option>
                </datalist>
              </Box>
            </>
          );
        }}
      />
    </FormField>
  );
}
