import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";

type Props = {
  dateLabel?: string;
  disableFuture?: boolean;
  name: string;
  required?: boolean;
  timeLabel?: string;
};

export function DateTimePicker(props: Props) {
  const {
    dateLabel = "Date",
    disableFuture = false,
    name,
    required = false,
    timeLabel = "Time",
  } = props;
  const { control } = useFormContext();
  const now = new Date();

  return (
    <Controller
      {...{ control, name }}
      render={({ field: { onBlur, onChange, value } }) => (
        <>
          <FormField {...{ name, required }} label={dateLabel}>
            <DatePicker
              {...{ onBlur, onChange, value }}
              inputFormat="MM/dd/yyyy"
              mask="__/__/____"
              maxDate={disableFuture ? now : undefined}
              renderInput={(params) => (
                <TextField {...{ required }} {...params} margin="normal" />
              )}
            />
          </FormField>

          <FormField {...{ name, required }} label={timeLabel}>
            <TimePicker
              {...{ onBlur, onChange, value }}
              mask="__:__ _M"
              maxTime={disableFuture ? now : undefined}
              renderInput={(params) => (
                <TextField {...{ required }} {...params} margin="normal" />
              )}
            />
          </FormField>
        </>
      )}
    />
  );
}
