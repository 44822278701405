import { useRouter } from "next/router";
import useSWR from "swr";

import { useProtectedRoute } from "@/hooks";

import type { Opportunity } from "@/types/models";
export function useOpportunity() {
  const user = useProtectedRoute();
  const router = useRouter();
  const {
    asPath,
    isReady,
    query: { id },
  } = router;
  const { data: opportunity, mutate } = useSWR<Opportunity>(
    user && isReady ? `/api/opportunities/${id}` : null
  );

  return {
    asPath,
    isLoading: !opportunity,
    mutate,
    opportunity,
  };
}
