import { useCallback } from "react";

import {
  OpportunityListItem,
  ResourceList,
  ResourceListEmptyState
} from "@/components";
import { useInfiniteList, useInfiniteOpportunities } from "@/hooks";

import type { Opportunity } from "@/types/models";

type Props = {
  clientId?: number;
  onSelected?: (opportunity: Opportunity) => void;
};

export function Opportunities(props: Props) {
  const { clientId, onSelected } = props;
  const response = useInfiniteOpportunities({ clientId });
  const itemContent = useCallback(
    function itemContent(_, opportunity) {
      return <OpportunityListItem {...{ onSelected, opportunity }} />;
    },
    [onSelected]
  );
  const infiniteListProps = useInfiniteList<Opportunity>({
    itemContent,
    response,
  });

  return infiniteListProps.isEmpty ? (
    <ResourceListEmptyState>No Opportunities</ResourceListEmptyState>
  ) : (
    <ResourceList<Opportunity> {...infiniteListProps} />
  );
}
