import { useFormContext } from "react-hook-form";

import {
  AddClientButton,
  Clients,
  FormField,
  H4,
  ResourceHeader,
  ResourceSearchForm,
  ResourceSearchProvider,
  ResourceSheet,
  ResourceSheetContent,
  Text
} from "@/components";
import { useSheet } from "@/hooks";
import { Flex, IconButton, SheetTrigger } from "@modulz/design-system";
import { Pencil1Icon } from "@radix-ui/react-icons";

import type { Client } from "@/types/models";

function SelectClient(props: { name: string }) {
  const { setValue } = useFormContext();
  const { onOpenChange } = useSheet();

  function handleSelected(client: Client) {
    setValue(props.name, client.id);
    onOpenChange(false);
  }

  return <Clients onSelected={handleSelected} />;
}

function SelectClientField(props: { name: string }) {
  return (
    <ResourceSearchProvider>
      <ResourceSheet>
        <ResourceSheetContent>
          <ResourceHeader>
            <H4>Select Client</H4>
            <ResourceSearchForm placeholder="Search Clients..." />
            <AddClientButton />
          </ResourceHeader>

          <SelectClient {...props} />
        </ResourceSheetContent>
        <SheetTrigger asChild>
          <IconButton>
            <Pencil1Icon height={20} width={20} />
          </IconButton>
        </SheetTrigger>
      </ResourceSheet>
    </ResourceSearchProvider>
  );
}

type Props = {
  client?: Client;
  name?: string;
};

export function ClientField(props: Props) {
  const { client, name = "client_id" } = props;

  return (
    <FormField {...{ name }} label="Client" selectable={false} required>
      <Flex align="center" gap="3">
        <Text size="4">{client?.organization_name ?? "..."}</Text>
        <SelectClientField {...{ name }} />
      </Flex>
    </FormField>
  );
}
