import { addMonths, formatISOWithOptions } from "date-fns/fp";
import { useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";

import {
  AddResourceForm,
  AddSubmitButton,
  Column,
  DatePicker,
  Option,
  PercentField,
  ProposalEffortField,
  RadioGroupField,
  SelectField
} from "@/components";
import { useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Button, css, Flex, Radio } from "@modulz/design-system";

import type {
  ContractStructure,
  ContractType,
  EvaluationPoint,
  GoNoGo,
  Opportunity,
  Responsibility,
} from "@/types/models";

const RESOURCE = Resource.GO_NO_GOS;
const addOneMonth = addMonths(1);
const flex = css({ mt: "$5" });

type Data = {
  contractStructures: ContractStructure[];
  contractTypes: ContractType[];
  evaluationPoints: EvaluationPoint[];
  opportunity: Opportunity;
  responsibilities: Responsibility[];
};

type FormValues = {
  contract_structure_id: number;
  contract_type_id: number;
  evaluation_point_option_ids: number[];
  opportunity_id: number;
  proposal_due_date: string;
  responsibility_id: number;
  estimated_labor_hours: number | null;
  win_probability: number;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: {
      contractStructures,
      contractTypes,
      evaluationPoints,
      opportunity,
      responsibilities,
    },
    hideForm,
  } = props;
  const { id: opportunity_id } = opportunity;
  const initialEstimatedLaborHours = null;
  const defaultValues = {
    contract_structure_id: contractStructures[0].id,
    contract_type_id: contractTypes[0].id,
    estimated_labor_hours: initialEstimatedLaborHours,
    evaluation_point_option_ids: evaluationPoints.map(
      (ep) => ep.evaluation_point_options[0].id
    ),
    opportunity_id,
    proposal_due_date: formatISOWithOptions(
      { representation: "date" },
      addOneMonth(new Date())
    ),
    responsibility_id: responsibilities[0].id,
    win_probability: 0.5,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, GoNoGo>({
    defaultValues,
    methods,
    onSubmitSuccess: handleSubmitSuccess,
    resourceData: {
      endpoint: "/api/go-no-gos",
      resource: RESOURCE,
      singularName: "Go/No Go",
    },
  });
  const { mutate } = useSWRConfig();

  function handleSubmitSuccess() {
    hideForm();
    mutate(`/api/opportunities/${opportunity_id}`);
  }

  return (
    <AddResourceForm {...{ methods, onSubmit }} defaultActions={false}>
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <DatePicker
            label="Proposal due date"
            name="proposal_due_date"
            required
          />

          <PercentField
            label="Win probability"
            name="win_probability"
            required
          />

          <SelectField
            label="Contract type"
            name="contract_type_id"
            required
            size="2"
            valueAsNumber
          >
            {contractTypes.map((ct) => (
              <Option key={ct.id} size="2" value={ct.id}>
                {ct.title}
              </Option>
            ))}
          </SelectField>

          <SelectField
            label="Contract structure"
            name="contract_structure_id"
            required
            size="2"
            valueAsNumber
          >
            {contractStructures.map((cs) => (
              <Option key={cs.id} size="2" value={cs.id}>
                {cs.title}
              </Option>
            ))}
          </SelectField>

          <SelectField
            label="Responsibility"
            name="responsibility_id"
            required
            size="2"
            valueAsNumber
          >
            {responsibilities.map((r) => (
              <Option key={r.id} size="2" value={r.id}>
                {r.title}
              </Option>
            ))}
          </SelectField>
        </Column>

        <Column size="2">
          {evaluationPoints.map((ep, index) => {
            const commonProps = {
              key: ep.id,
              name: `evaluation_point_option_ids[${index}]`,
            };

            return ep.title === "Proposal Effort" ? (
              <ProposalEffortField
                {...commonProps}
                {...{ initialEstimatedLaborHours, opportunity }}
                evaluationPoint={ep}
              />
            ) : (
              <RadioGroupField
                {...commonProps}
                description={ep.description}
                label={`${ep.title}*`}
              >
                {ep.evaluation_point_options.map(
                  ({ id, description, title }) => {
                    const value = id.toString();

                    return (
                      <Flex align="center" gap="2" key={id}>
                        <Radio {...{ value }} id={value} size="2" />
                        <Flex direction="column" gap="1">
                          <label htmlFor={value}>{title}</label>
                          {description}
                        </Flex>
                      </Flex>
                    );
                  }
                )}
              </RadioGroupField>
            );
          })}
        </Column>
      </Flex>

      <Flex align="center" className={flex()} gap="5">
        <Button onClick={hideForm} size="2" tabIndex={-1} type="button">
          Cancel
        </Button>

        <AddSubmitButton />
      </Flex>
    </AddResourceForm>
  );
}

export function AddGoNoGo(props: { id: number; hideForm: () => void }) {
  const { data: opportunity } = useSWR(`/api/opportunities/${props.id}`);
  const { data: contractStructures } = useSWR<ContractStructure[]>(
    "/api/contract-structures"
  );
  const { data: contractTypes } = useSWR<ContractType[]>("/api/contract-types");
  const { data: evaluationPoints } = useSWR<EvaluationPoint[]>(
    "/api/evaluation-points"
  );
  const { data: responsibilities } = useSWR<Responsibility[]>(
    "/api/responsibilities"
  );

  if (
    !contractStructures ||
    !contractTypes ||
    !evaluationPoints ||
    !opportunity ||
    !responsibilities
  ) {
    return null;
  }

  return (
    <Form
      {...props}
      data={{
        contractStructures,
        contractTypes,
        evaluationPoints,
        opportunity,
        responsibilities,
      }}
    />
  );
}
