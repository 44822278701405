import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import { useIsMounted } from "@/hooks";

import type { MutableRefObject } from "react";

type TSheetContext = {
  onOpenChange: (isOpen: boolean) => void;
  open: boolean;
  sheetContentRef: MutableRefObject<HTMLDivElement | null>;
};

export const SheetContext = createContext<TSheetContext>(null as any);

export function useSheetState() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sheetContentRef = useRef<HTMLDivElement>(null);
  const parentSheet = useSheet();
  const isMounted = useIsMounted();
  const handleOpenChange = useCallback(
    function handleOpenChange(open: boolean) {
      if (isMounted() && isOpen !== open) {
        setIsOpen(open);
      }
    },
    [isOpen, isMounted, setIsOpen]
  );

  useEffect(() => {
    if (!parentSheet?.sheetContentRef.current) {
      return;
    }

    const parentSheetContentEl = parentSheet.sheetContentRef.current;
    const parentIsExpanded =
      parentSheetContentEl.dataset.expanded === "expanded";

    if (isOpen !== parentIsExpanded) {
      parentSheetContentEl.dataset.expanded = parentIsExpanded
        ? "normal"
        : "expanded";
    }
  }, [isOpen, parentSheet]);

  return {
    onOpenChange: handleOpenChange,
    open: isOpen,
    parentSheet,
    sheetContentRef,
  };
}

export function useSheet() {
  return useContext(SheetContext);
}
