import { useSheet } from "@/hooks";
import { css, Flex } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type { CSS } from "@modulz/design-system/dist/stitches.config";

const header = css({
  backgroundColor: "$loContrast",
  display: "flex",
  jc: "center",
  pb: "$5",
  pt: "$5",
  variants: {
    sheet: {
      true: {
        backgroundColor: "$panel",
        pt: "$2",
      },
    },
    sticky: {
      true: {
        "@bp2": {
          position: "sticky",
          top: 72,
          zIndex: 1000,
        },
      },
    },
  },
});
const container = css({
  flexGrow: 1,
  px: "$5",
  variants: {
    size: {
      "1": { maxWidth: 430 },
      "2": { maxWidth: 715 },
      "3": { maxWidth: 1145 },
    },
  },
  defaultVariants: { size: "3" },
});

export function ResourceHeader(
  props: ComponentPropsWithoutRef<"header"> & { css?: CSS }
) {
  const isSheet = Boolean(useSheet());
  const { children, className, css, ...rest } = props;

  return (
    <header
      {...rest}
      className={header({
        className,
        css,
        sheet: isSheet,
        sticky: !isSheet,
      })}
    >
      <Flex className={container()} direction="column" gap="5">
        {children}
      </Flex>
    </header>
  );
}
