import {
  AddClientButton,
  Clients,
  H4,
  ResourceSearchForm,
  ResourceSearchProvider,
  SearchDialog
} from "@/components";
import { useSheet } from "@/hooks";
import { Box, css } from "@modulz/design-system";

const box = css({
  height: "100%",
  maxWidth: 430,
  width: "100%",
  variants: {
    sheet: { true: { display: "flex", fd: "column" } },
  },
});
const header = css({
  bc: "$loContrast",
  display: "flex",
  fd: "column",
  gap: "$4",
  pb: "$5",
  px: "$5",

  "@bp1": {
    position: "sticky",
    px: 0,
    top: 163,
    zIndex: 1000,
  },

  variants: {
    sheet: {
      true: {
        mt: "$2",
        "@bp1": {
          position: "static",
          top: "initial",
          zIndex: "initial",
        },
      },
    },
  },
});
const h4 = css({ variants: { sheet: { true: { px: "$5" } } } });

export function SelectInitialClient() {
  const isSheet = Boolean(useSheet());
  const searchEl = <ResourceSearchForm placeholder="Search Clients..." />;
  const clientsEl = <Clients />;

  return (
    <ResourceSearchProvider>
      <Box className={box({ sheet: isSheet })}>
        <header className={header({ sheet: isSheet })}>
          <H4 className={h4({ sheet: isSheet })}>Select Client</H4>
          {searchEl}
          <AddClientButton />
        </header>

        {clientsEl}
      </Box>
      <SearchDialog search={searchEl}>{clientsEl}</SearchDialog>
    </ResourceSearchProvider>
  );
}
