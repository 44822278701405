import { useRouter } from "next/router";
import useSWR from "swr";

import { useProtectedRoute } from "@/hooks";

import type { ClientContactReport } from "@/types/models";

export function useCCR() {
  const user = useProtectedRoute();
  const router = useRouter();
  const {
    asPath,
    isReady,
    query: { id },
  } = router;
  const { data: ccr } = useSWR<ClientContactReport>(
    user && isReady ? `/api/ccrs/${id}` : null
  );

  return {
    asPath,
    ccr,
    isLoading: !isReady || (isReady && !ccr),
  };
}
