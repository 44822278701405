import { forwardRef } from "react";

import { css } from "@modulz/design-system";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import type {
  CSS,
  VariantProps
} from "@modulz/design-system/dist/stitches.config";
const dialogContent = css({
  backgroundColor: "$panel",
  borderRadius: 0,
  position: "fixed",
  "&:focus": {
    outline: "none",
  },

  variants: {
    fullScreen: {
      true: {
        bottom: 0,
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
      },
    },
  },
  defaultVariants: {
    fullScreen: true,
  },
});

type DialogContentPrimitiveProps = Omit<
  React.ComponentProps<typeof DialogPrimitive.Content>,
  "as"
>;
type DialogContentProps = DialogContentPrimitiveProps &
  VariantProps<typeof dialogContent> & { css?: CSS };

const FullScreenDialogContent = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ children, ...props }, forwardedRef) => (
  <DialogPrimitive.Content
    {...props}
    className={dialogContent()}
    ref={forwardedRef}
  >
    {children}
  </DialogPrimitive.Content>
));

FullScreenDialogContent.displayName = "FullScreenDialogContent";

export { FullScreenDialogContent };
