import NextLink from "next/link";
import useSWR from "swr";

import { useUser } from "@/hooks";
import { ApplicationRole, Role } from "@/types/models";
import { Box, css, Flex, Link } from "@modulz/design-system";
import { DownloadIcon } from "@radix-ui/react-icons";

import type { Report } from "@/types/misc";

const box = css({ height: 20 });

type Props = {
  id?: Report;
  resource: string;
};

export function ExcelExportButton(props: Props) {
  const { id, resource } = props;
  const { data } = useSWR(`/api/${resource}?$limit=1`);
  const user = useUser();
  const emptyEl = <Box className={box()} />;

  if (!id || !user) {
    return emptyEl;
  }

  return data?.length &&
    !user.roles.some(
      (role: Role) => role.title === ApplicationRole.ACCOUNTING
    ) ? (
    <NextLink href={`/api/reports/${id}`} passHref>
      <Link download target="_blank">
        <Flex align="center" gap="1">
          <DownloadIcon height={20} width={20} />
          EXPORT TO EXCEL
        </Flex>
      </Link>
    </NextLink>
  ) : (
    emptyEl
  );
}
