import { css } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "@modulz/design-system/dist/stitches.config";

const option = css({
  variants: { size: { "1": {}, "2": { fontSize: "$4" } } },
  defaultVariants: { size: "1" },
});

export function Option(
  props: ComponentPropsWithoutRef<"option"> & VariantProps<typeof option>
) {
  const { size, ...rest } = props;
  return <option className={option({ size })} {...rest} />;
}
