import { useRouter } from "next/router";

import { AddSubmitButton, Link } from "@/components";
import { useSheet } from "@/hooks";
import { Button, Flex } from "@modulz/design-system";

export function AddResourceFormActions() {
  const router = useRouter();
  const sheet = useSheet();
  const isSheet = Boolean(sheet);

  function handleCancel() {
    if (isSheet) {
      sheet.onOpenChange(false);
    }
  }

  return (
    <Flex align="center" gap="5">
      {isSheet ? (
        <Button onClick={handleCancel} size="2" tabIndex={-1} type="button">
          Cancel
        </Button>
      ) : (
        <Link href={router.query.return_to as string} tabIndex={-1}>
          Cancel
        </Link>
      )}

      <AddSubmitButton />
    </Flex>
  );
}
