import { headingStyles, text } from "@/components/Text";
import { css } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type {
  CSS,
  VariantProps,
} from "@modulz/design-system/dist/stitches.config";

const h1 = css({
  ...headingStyles,
  defaultVariants: { ...headingStyles.defaultVariants, size: "4" },
});

export function H1(
  props: ComponentPropsWithoutRef<"h1"> &
    VariantProps<typeof text> & { css?: CSS }
) {
  const { className, css, gradient, variant, ...rest } = props;

  return <h1 {...rest} className={h1({ className, css, gradient, variant })} />;
}
