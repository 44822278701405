import { createContext, useCallback, useContext, useState } from "react";
import useSWR from "swr";

import type { Client } from "@/types/models";

export type TInitialClientContext = {
  initialClientId?: number;
  initialClient?: Client;
  onSelected: (client: Client) => void;
};

export const InitialClientContext = createContext<TInitialClientContext | null>(
  null as any
);

export function useInitialClientState(clientId?: number) {
  const [initialClientId, setInitialClientId] = useState<number | undefined>(
    clientId
  );
  const { data: initialClient } = useSWR<Client>(
    initialClientId ? `/api/clients/${initialClientId}` : null
  );
  const handleClientSelected = useCallback(
    function (client: Client) {
      setInitialClientId(client.id);
      window.scrollTo({ top: 0 });
    },
    [setInitialClientId]
  );

  return {
    initialClient,
    initialClientId,
    onSelected: handleClientSelected,
  };
}

export function useInitialClient() {
  return useContext(InitialClientContext);
}
