import { AddClient, ResourceSheet, ResourceSheetContent } from "@/components";
import { Button, css, SheetTrigger } from "@modulz/design-system";

const button = css({
  alignSelf: "center!important",
  flexShrink: "1!important",
});

export function AddClientButton() {
  return (
    <ResourceSheet>
      <ResourceSheetContent>
        <AddClient />
      </ResourceSheetContent>
      <SheetTrigger asChild>
        <Button className={button()} size="2">
          Add Client
        </Button>
      </SheetTrigger>
    </ResourceSheet>
  );
}
