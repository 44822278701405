import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  Column,
  Option,
  SelectField,
  TextField,
  UpdateResourceForm
} from "@/components";
import { useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Client, ClientSector, ClientType } from "@/types/models";
import type { Id } from "@/types/resources";

const RESOURCE = Resource.CLIENTS;

type FormValues = {
  client_sector_id: number;
  client_type_id: number;
  organization_name: string;
};

type Data = {
  client: Client;
  clientSectors: ClientSector[];
  clientTypes: ClientType[];
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: {
      client: { id, client_sector_id, client_type_id, organization_name },
      clientSectors,
      clientTypes,
    },
    hideForm,
  } = props;
  const methods = useForm<FormValues>({
    defaultValues: { client_sector_id, client_type_id, organization_name },
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Client>({
    id,
    methods,
    onSubmitSuccess: hideForm,
    resourceData: {
      endpoint: "/api/clients",
      resource: RESOURCE,
      singularName: "Client",
    },
  });

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <TextField
            autoCapitalize="words"
            autoFocus
            label="Organization name"
            name="organization_name"
            placeholder="Port of Seattle"
            required
          />

          <SelectField
            label="Client sector"
            name="client_sector_id"
            required
            size="2"
            valueAsNumber
          >
            {clientSectors.map((cs) => (
              <Option key={cs.id} size="2" value={cs.id}>
                {cs.title}
              </Option>
            ))}
          </SelectField>

          <SelectField
            label="Client type"
            name="client_type_id"
            required
            size="2"
            valueAsNumber
          >
            {clientTypes.map((ct) => (
              <Option key={ct.id} size="2" value={ct.id}>
                {ct.title}
              </Option>
            ))}
          </SelectField>
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateClient(props: { id: Id; hideForm: () => void }) {
  const { id, hideForm } = props;
  const { data: client } = useSWR<Client>(`/api/clients/${id}`);
  const { data: clientSectors } = useSWR<ClientSector[]>("/api/client-sectors");
  const { data: clientTypes } = useSWR<ClientType[]>("/api/client-types");

  if (!client || !clientSectors || !clientTypes) {
    return null;
  }

  return (
    <Form {...{ hideForm }} data={{ client, clientSectors, clientTypes }} />
  );
}
