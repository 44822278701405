import toast from "react-hot-toast";

import { patch, post } from "@/lib/fetch-json";
import { Resource } from "@/types/resources";

import type { FieldNamesMarkedBoolean } from "react-hook-form";
import type { Id, ResourceData } from "@/types/resources";

export function fromDB<T>(data: T): T {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => value !== null && value !== undefined
    )
  ) as T;
}

function toDB(data: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === "" ? null : value,
    ])
  );
}

export function getMutationPayload<T>({
  data,
  defaultValues,
  dirtyFields,
}: {
  data: Partial<T>;
  defaultValues?: Partial<T>;
  dirtyFields: FieldNamesMarkedBoolean<T>;
}): Partial<T> {
  return toDB({
    ...defaultValues,
    ...Object.fromEntries(
      Object.keys(dirtyFields).map((key: string) => [key, data[key as keyof T]])
    ),
  }) as Partial<T>;
}

export function submitAddForm<T, U>({
  data,
  defaultValues,
  dirtyFields,
  resource,
}: {
  data: T;
  defaultValues?: Partial<T>;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  resource: Resource;
}): Promise<U> {
  return post<T, U>(
    `/api/${resource}`,
    getMutationPayload<T>({ data, defaultValues, dirtyFields })
  );
}

export async function onSubmitAdd<T, U>({
  data,
  defaultValues,
  dirtyFields,
  mutate,
  resourceData,
}: {
  data: T;
  defaultValues?: Partial<T>;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  mutate: (endpoint: string) => void;
  resourceData: ResourceData;
}): Promise<U> {
  const { endpoint, resource, singularName } = resourceData;
  const added = await submitAddForm<T, U>({
    data,
    defaultValues,
    dirtyFields,
    resource,
  });
  mutate(endpoint);
  toast.success(`Added ${singularName}`);
  return added;
}

export function submitUpdateForm<T, U>({
  id,
  data,
  dirtyFields,
  resource,
}: {
  id: Id;
  data: T;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  resource: Resource;
}): Promise<U> {
  return patch<T, U>(
    `/api/${resource}/${id}`,
    getMutationPayload<T>({ data, dirtyFields })
  );
}

export async function onSubmitUpdate<T, U>({
  id,
  data,
  dirtyFields,
  mutate,
  resourceData,
}: {
  id: Id;
  data: T;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  mutate: (endpoint: string) => void;
  resourceData: ResourceData;
}): Promise<U> {
  const { endpoint, resource, singularName } = resourceData;
  const updated = await submitUpdateForm<T, U>({
    id,
    data,
    dirtyFields,
    resource,
  });
  mutate(endpoint);
  toast.success(`Updated ${singularName}`);
  return updated;
}
