import { useRouter } from "next/router";
import { createContext, useCallback, useContext } from "react";

type TNavigationMenuContext = [boolean, (isOpen: boolean) => void];

export const NavigationMenuContext = createContext<TNavigationMenuContext>([
  false,
  () => {},
]);

export function useNavigationMenuState() {
  const router = useRouter();
  const isNavMenuOpen = router.asPath.endsWith("#navmenu");
  const setIsNavMenuOpen = useCallback(
    function (isOpen: boolean) {
      if (isOpen === isNavMenuOpen) {
        return;
      }

      if (isOpen) {
        router.push("#navmenu");
      } else {
        router.back();
      }
    },
    [isNavMenuOpen, router]
  );

  return [isNavMenuOpen, setIsNavMenuOpen] as TNavigationMenuContext;
}

export function useNavigationMenu() {
  return useContext(NavigationMenuContext);
}
