import { addMonths, formatISOWithOptions } from "date-fns/fp";
import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  AddResourceForm,
  AddResourceFormActions,
  ClientField,
  Column,
  DatePicker,
  Option,
  PercentField,
  RadioGroupField,
  SelectField,
  SelectInitialClient,
  SwitchField,
  Text,
  TextAreaField,
  TextField,
} from "@/components";
import { useClientRelation, useInitialClient, useResourceForm } from "@/hooks";
import { SelectValue } from "@/types/misc";
import { Resource } from "@/types/resources";
import { Flex, Radio } from "@modulz/design-system";

import type {
  Client,
  Opportunity,
  Organization,
  ServiceLine,
  ServiceType,
  User,
} from "@/types/models";

const RESOURCE = Resource.OPPORTUNITIES;
const addOneMonth = addMonths(1);

type FormValues = {
  anticipated_award_date: string;
  anticipated_contract_amount: number;
  client_id: number;
  description?: string;
  go_probability: number;
  is_confidential: boolean;
  organization_id: number;
  principal_user_id: string;
  program_manager_user_id?: string;
  project_manager_user_id: string;
  service_type_id: number;
  title: string;
};

type Data = {
  client?: Client;
  organizations: Organization[];
  principals: User[];
  programManagers: User[];
  projectManagers: User[];
  serviceLines: ServiceLine[];
  serviceTypes: ServiceType[];
};

function Form(props: { data: Data; initialClient: Client }) {
  const {
    data: {
      organizations,
      principals,
      programManagers,
      projectManagers,
      serviceLines,
      serviceTypes,
    },
    initialClient,
  } = props;
  const { id: initialClientId } = initialClient;
  const hasProgramManagers = programManagers.length > 0;
  const defaultValues = {
    client_id: initialClientId,
    organization_id: organizations.find(
      (o: Organization) => o.title === "SoundEarth Strategies"
    )!.id,
    principal_user_id: principals[0].id,
    ...(hasProgramManagers && { program_manager_user_id: SelectValue.EMPTY }),
    project_manager_user_id: projectManagers[0].id,
    service_type_id: serviceTypes[0].id,
    anticipated_award_date: formatISOWithOptions(
      { representation: "date" },
      addOneMonth(new Date())
    ),
    anticipated_contract_amount: 0,
    go_probability: 0.5,
    is_confidential: false,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Opportunity>({
    defaultValues,
    methods,
    resourceData: {
      endpoint: "/api/opportunities",
      resource: RESOURCE,
      singularName: "Opportunity",
    },
  });
  const client = useClientRelation<FormValues>({
    control: methods.control,
    defaultValue: initialClientId,
    fallbackData: initialClient,
  });
  const serviceTypesByServiceLineId: Record<number, ServiceType[]> =
    Object.fromEntries(
      serviceLines.map((sl) => [
        sl.id,
        serviceTypes.filter((st) => st.service_line_id === sl.id),
      ])
    );

  console.log({ wv: methods.watch() });

  return (
    <>
      <AddResourceForm {...{ methods, onSubmit }} defaultActions={false}>
        <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
          <Column>
            <ClientField {...{ client }} />

            <RadioGroupField
              label="Organization*"
              name="organization_id"
              valueAsNumber
            >
              {organizations.map(({ id, title }) => {
                const value = id.toString();

                return (
                  <Flex align="center" gap="2" key={id}>
                    <Radio {...{ value }} id={value} size="2" />
                    <label htmlFor={value}>{title}</label>
                  </Flex>
                );
              })}
            </RadioGroupField>

            <TextField
              autoFocus
              label="Title"
              name="title"
              placeholder="Port of Seattle project XYZ"
              required
            />

            <TextAreaField
              label="Description"
              name="description"
              placeholder="Port of Seattle project XYZ description"
              rows={4}
            />

            <SelectField
              label="Service type"
              name="service_type_id"
              required
              size="2"
              valueAsNumber
            >
              {serviceLines.map((sl) => (
                <optgroup key={sl.id} label={sl.title}>
                  {serviceTypesByServiceLineId[sl.id].map((st) => (
                    <option key={`${sl.id}-${st.id}`} value={st.id}>
                      {st.title}
                    </option>
                  ))}
                </optgroup>
              ))}
            </SelectField>
          </Column>

          <Column>
            <TextField
              inputMode="numeric"
              label="Anticipated contract amount"
              min={0}
              name="anticipated_contract_amount"
              placeholder="50000"
              required
              startAdornment={<Text size="4">$</Text>}
              step={500}
              type="number"
            />

            <DatePicker
              label="Anticipated award date"
              name="anticipated_award_date"
              required
            />

            <PercentField
              label="Go probability"
              name="go_probability"
              required
            />

            <SwitchField
              label="Confidential?"
              name="is_confidential"
              required
            />
          </Column>

          <Column>
            <SelectField
              label="Project manager"
              name="project_manager_user_id"
              required
              size="2"
            >
              {projectManagers.map((pm) => (
                <Option key={pm.id} size="2" value={pm.id}>
                  {pm.full_name}
                </Option>
              ))}
            </SelectField>

            <SelectField
              label="Principal"
              name="principal_user_id"
              required
              size="2"
            >
              {principals.map((pm) => (
                <Option key={pm.id} size="2" value={pm.id}>
                  {pm.full_name}
                </Option>
              ))}
            </SelectField>

            {hasProgramManagers && (
              <SelectField
                label="Program manager"
                name="program_manager_user_id"
                size="2"
              >
                {programManagers.map((pm) => (
                  <Option key={pm.id} size="2" value={pm.id}>
                    {pm.full_name}
                  </Option>
                ))}
              </SelectField>
            )}
          </Column>
        </Flex>

        <AddResourceFormActions />
      </AddResourceForm>
    </>
  );
}

export function AddOpportunity() {
  const { initialClient, initialClientId } = useInitialClient() ?? {};
  const { data: organizations } = useSWR<Organization[]>("/api/organizations");
  const { data: principals } = useSWR<User[]>("/api/principals");
  const { data: programManagers } = useSWR<User[]>("/api/program-managers");
  const { data: projectManagers } = useSWR<User[]>("/api/project-managers");
  const { data: serviceLines } = useSWR<ServiceLine[]>("/api/service-lines");
  const { data: serviceTypes } = useSWR<ServiceType[]>("/api/service-types");

  if (
    (initialClientId && !initialClient) ||
    !organizations ||
    !principals ||
    !programManagers ||
    !projectManagers ||
    !serviceLines ||
    !serviceTypes
  ) {
    return null;
  }

  return initialClient ? (
    <Form
      {...{ initialClient }}
      data={{
        organizations,
        principals,
        programManagers,
        projectManagers,
        serviceLines,
        serviceTypes,
      }}
    />
  ) : (
    <SelectInitialClient />
  );
}
