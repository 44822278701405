import qs from "qs";
import Cookies from "universal-cookie";

import {
  SESSION_ID_COOKIE_NAME,
  TOKEN_EXP_COOKIE_NAME
} from "@/lib/auth-cookies";
import { FetchError } from "@/lib/errors";
import { isBrowser } from "@/lib/utils";

export enum HttpMethod {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
}

export const jsonContentTypeHeader = { "Content-Type": "application/json" };
export const BEFORE_FETCH_EVENT = "BEFORE_TOKEN_REFRESH_EVENT";
export const AFTER_FETCH_EVENT = "AFTER_TOKEN_REFRESH_EVENT";
const beforeFetchEvent = isBrowser ? new Event(BEFORE_FETCH_EVENT) : undefined;
const afterFetchEvent = isBrowser ? new Event(AFTER_FETCH_EVENT) : undefined;
const denyList = ["/api/sign-in"];
const _isRefreshingToken: Record<string, any> = {};

function dispatchEvent(event?: Event) {
  if (!event) {
    return;
  }

  if (isBrowser) {
    document.dispatchEvent(event);
  }
}

async function refreshSession() {
  const response = await fetch("/api/token", {
    body: JSON.stringify({}),
    headers: { ...jsonContentTypeHeader },
    method: HttpMethod.POST,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function fetchJSON(input: RequestInfo, init?: RequestInit) {
  try {
    dispatchEvent(beforeFetchEvent);
    const _input = input;
    const _init = init ?? {};
    const _pathname = new URL(
      typeof _input === "string" ? `http://example.com${_input}` : _input.url
    ).pathname;

    if (isBrowser) {
      const cookies = new Cookies();
      const sessionId = cookies.get(SESSION_ID_COOKIE_NAME);

      _init.credentials = "same-origin";

      const tokenExpCookie = cookies.get(TOKEN_EXP_COOKIE_NAME);

      if (sessionId && !tokenExpCookie && !denyList.includes(_pathname)) {
        if (!_isRefreshingToken[sessionId]) {
          _isRefreshingToken[sessionId] = refreshSession();
        }

        await _isRefreshingToken[sessionId];
      }
    }

    const response = await fetch(_input, _init);
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new FetchError(response.statusText, data, response);
  } catch (err: any) {
    console.error({ err });

    if (!err.data) {
      err.data = { message: err.message };
    }

    throw err;
  } finally {
    dispatchEvent(afterFetchEvent);
  }
}

export function getQueryString(params = {}) {
  if (Object.keys(params).length === 0) {
    return "";
  }

  return `?${qs.stringify(params)}`;
}

export function patch<T, U = T>(
  input: RequestInfo,
  data: Partial<T>,
  init?: RequestInit
): Promise<U> {
  return fetchJSON(input, {
    body: JSON.stringify(data),
    headers: { ...jsonContentTypeHeader, ...init?.headers },
    method: HttpMethod.PATCH,
    ...init,
  });
}

export function post<T, U = T>(
  input: RequestInfo,
  data: Partial<T>,
  init?: RequestInit
): Promise<U> {
  return fetchJSON(input, {
    body: JSON.stringify(data),
    headers: { ...jsonContentTypeHeader, ...init?.headers },
    method: HttpMethod.POST,
  });
}

export function remove<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  return fetchJSON(input, { method: HttpMethod.DELETE, ...init });
}
