import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  ClientField,
  Column,
  DatePicker,
  Option,
  PercentField,
  RadioGroupField,
  SelectField,
  SwitchField,
  Text,
  TextAreaField,
  TextField,
  UpdateResourceForm,
} from "@/components";
import { useClientRelation, useResourceForm } from "@/hooks";
import { SelectValue } from "@/types/misc";
import { ApprovalType, Decision, OpportunityStatus } from "@/types/models";
import { Resource } from "@/types/resources";
import { Flex, Radio } from "@modulz/design-system";

import type {
  Opportunity,
  Organization,
  ServiceLine,
  ServiceType,
  User,
} from "@/types/models";
import type { Id } from "@/types/resources";

const RESOURCE = Resource.OPPORTUNITIES;
const assignableStatusesByProposalApproval = {
  [true.toString()]: [
    OpportunityStatus.AWARDED,
    OpportunityStatus.DROPPED,
    OpportunityStatus.LOST,
  ],
  [false.toString()]: [OpportunityStatus.DROPPED],
};

type FormValues = {
  client_id: number;
  organization_id: number;
  principal_user_id: string;
  program_manager_user_id?: string;
  project_manager_user_id: string;
  service_type_id: number;
  anticipated_award_date: string;
  anticipated_contract_amount: number;
  description?: string;
  go_probability: number;
  is_confidential: boolean;
  status: OpportunityStatus | SelectValue;
  title: string;
};

type Data = {
  opportunity: Opportunity;
  organizations: Organization[];
  principals: User[];
  programManagers: User[];
  projectManagers: User[];
  serviceLines: ServiceLine[];
  serviceTypes: ServiceType[];
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: {
      opportunity: {
        id,
        approvals,
        client: initialClient,
        client_id,
        organization_id,
        principal_user_id,
        program_manager_user_id,
        project_manager_user_id,
        service_type_id,
        anticipated_award_date,
        anticipated_contract_amount,
        description,
        go_probability,
        is_confidential,
        status,
        title,
      },
      organizations,
      principals,
      programManagers,
      projectManagers,
      serviceLines,
      serviceTypes,
    },
    hideForm,
  } = props;
  const hasProgramManagers = programManagers.length > 0;
  const isProposalApproved = approvals.some(
    ({ decision, type }) =>
      type === ApprovalType.PROPOSAL && decision === Decision.APPROVED
  );
  const assignableStatuses =
    assignableStatusesByProposalApproval[isProposalApproved.toString()];
  const methods = useForm<FormValues>({
    defaultValues: {
      anticipated_award_date,
      anticipated_contract_amount,
      client_id,
      description: description ?? "",
      go_probability,
      is_confidential,
      organization_id,
      principal_user_id,
      ...(hasProgramManagers && {
        program_manager_user_id: program_manager_user_id ?? SelectValue.EMPTY,
      }),
      project_manager_user_id,
      service_type_id,
      status: assignableStatuses.includes(status) ? status : SelectValue.EMPTY,
      title,
    },
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Opportunity>({
    id,
    methods,
    onSubmitSuccess: hideForm,
    resourceData: {
      endpoint: "/api/opportunities",
      resource: RESOURCE,
      singularName: "Opportunity",
    },
  });
  const clientRelation = useClientRelation<FormValues>({
    control: methods.control,
    defaultValue: client_id,
    fallbackData: initialClient,
  });
  const client = clientRelation ?? initialClient;
  const serviceTypesByServiceLineId: Record<number, ServiceType[]> =
    Object.fromEntries(
      serviceLines.map((sl) => [
        sl.id,
        serviceTypes.filter((st) => st.service_line_id === sl.id),
      ])
    );

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <ClientField {...{ client }} />

          <RadioGroupField
            label="Organization*"
            name="organization_id"
            valueAsNumber
          >
            {organizations.map(({ id, title }) => {
              const value = id.toString();

              return (
                <Flex align="center" gap="2" key={id}>
                  <Radio {...{ value }} id={value} size="2" />
                  <label htmlFor={value}>{title}</label>
                </Flex>
              );
            })}
          </RadioGroupField>

          <TextField
            autoFocus
            label="Title"
            name="title"
            placeholder="Port of Seattle project XYZ"
            required
          />

          <TextAreaField
            label="Description"
            name="description"
            placeholder="Port of Seattle project XYZ description"
            rows={4}
          />

          <SelectField label="Status" name="status" size="2">
            {assignableStatuses.map((status) => (
              <Option key={status} size="2" value={status}>
                {status}
              </Option>
            ))}
          </SelectField>
        </Column>

        <Column>
          <SelectField
            label="Service type"
            name="service_type_id"
            required
            size="2"
            valueAsNumber
          >
            {serviceLines.map((sl) => (
              <optgroup key={sl.id} label={sl.title}>
                {serviceTypesByServiceLineId[sl.id].map((st) => (
                  <option key={`${sl.id}-${st.id}`} value={st.id}>
                    {st.title}
                  </option>
                ))}
              </optgroup>
            ))}
          </SelectField>

          <TextField
            inputMode="numeric"
            label="Anticipated contract amount"
            min={0}
            name="anticipated_contract_amount"
            placeholder="50000"
            required
            startAdornment={<Text size="4">$</Text>}
            step={500}
            type="number"
          />

          <DatePicker
            label="Anticipated award date"
            name="anticipated_award_date"
            required
          />

          <PercentField label="Go probability" name="go_probability" required />

          <SwitchField label="Confidential?" name="is_confidential" required />
        </Column>

        <Column>
          <SelectField
            label="Project manager"
            name="project_manager_user_id"
            required
            size="2"
          >
            {projectManagers.map((pm) => (
              <Option key={pm.id} size="2" value={pm.id}>
                {pm.full_name}
              </Option>
            ))}
          </SelectField>

          <SelectField
            label="Principal"
            name="principal_user_id"
            required
            size="2"
          >
            {principals.map((pm) => (
              <Option key={pm.id} size="2" value={pm.id}>
                {pm.full_name}
              </Option>
            ))}
          </SelectField>

          {hasProgramManagers && (
            <SelectField
              label="Program manager"
              name="program_manager_user_id"
              size="2"
            >
              {programManagers.map((pm) => (
                <Option key={pm.id} size="2" value={pm.id}>
                  {pm.full_name}
                </Option>
              ))}
            </SelectField>
          )}
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateOpportunity(props: { id: Id; hideForm: () => void }) {
  const { data: opportunity } = useSWR<Opportunity>(
    `/api/opportunities/${props.id}`
  );
  const { data: organizations } = useSWR<Organization[]>("/api/organizations");
  const { data: principals } = useSWR<User[]>("/api/principals");
  const { data: programManagers } = useSWR<User[]>("/api/program-managers");
  const { data: projectManagers } = useSWR<User[]>("/api/project-managers");
  const { data: serviceLines } = useSWR<ServiceLine[]>("/api/service-lines");
  const { data: serviceTypes } = useSWR<ServiceType[]>("/api/service-types");

  if (
    !opportunity ||
    !organizations ||
    !principals ||
    !programManagers ||
    !projectManagers ||
    !serviceLines ||
    !serviceTypes
  ) {
    return null;
  }

  return (
    <Form
      {...props}
      data={{
        opportunity,
        organizations,
        principals,
        programManagers,
        projectManagers,
        serviceLines,
        serviceTypes,
      }}
    />
  );
}
