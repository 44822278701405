import { Children, cloneElement } from "react";

import { useFixedBottom } from "@/hooks";
import { css } from "@modulz/design-system";

import type { VariantProps } from "@modulz/design-system/dist/stitches.config";

const div = css({ position: "fixed" });
const positionVariant: Record<string, Record<string, number>> = {
  "1": { bottom: 25 },
  "2": { bottom: 115 },
  "3": { bottom: 205 },
};
const nodeStyles = {
  position: "fixed",
  zIndex: 1000,
  variants: {
    position: positionVariant,
  },
  defaultVariants: {
    position: "1",
  },
};
const node = css(nodeStyles);

export function FixedBottom(
  props: VariantProps<typeof node> & { children?: JSX.Element }
) {
  const { children, position = 1 } = props;
  const { bottom, ref } = useFixedBottom(
    positionVariant[position as string].bottom
  );

  if (!children) {
    return null;
  }

  return (
    <>
      {cloneElement(Children.only(children), {
        className: node({ css: { ...children.props.style, bottom } }),
      })}
      <div {...{ ref }} className={div({ css: { bottom } })} />
    </>
  );
}
