import { useRouter } from "next/router";

import { useIsBrowser } from "@/hooks";

export function useWindowScrollableInfiniteList() {
  const isBrowser = useIsBrowser();
  const { isReady } = useRouter();

  return isBrowser && isReady;
}
