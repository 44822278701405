export type Id = number | string;

export type ResourceFilters = Record<string, number | string | undefined>;

export enum Resource {
  APPROVALS = "approvals",
  APPROVAL_REQUESTS = "approval-requests",
  CLIENT_CONTACT_REPORTS = "ccrs",
  CLIENT_SECTORS = "client-sectors",
  CLIENT_TYPES = "client-types",
  CLIENTS = "clients",
  CONTACTS = "contacts",
  CONTRACT_STRUCTURES = "contract-structures",
  CONTRACT_TYPES = "contract-types",
  EVALUATION_POINTS = "evaluation-points",
  GO_NO_GOS = "go-no-gos",
  ORGANIZATIONS = "organizations",
  OPPORTUNITIES = "opportunities",
  PRINCIPALS = "principals",
  PROGRAM_MANAGERS = "program-managers",
  PROJECT_MANAGERS = "project-managers",
  PROPOSALS = "proposals",
  REQUIRED_APPROVERS = "required-approvers",
  RESPONSIBILITIES = "responsibilities",
  SERVICE_LINES = "service-lines",
  SERVICE_TYPES = "service-types",
  USERS = "users",
}

export type ResourceData = {
  endpoint: string;
  resource: Resource;
  singularName: string;
};
