import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";

import {
  Column,
  Option,
  SelectField,
  Text,
  TextField,
  UpdateResourceForm
} from "@/components";
import { useResourceForm } from "@/hooks";
import { CostProposalType } from "@/types/models";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Proposal } from "@/types/models";

const RESOURCE = Resource.PROPOSALS;

type Data = {
  proposal: Proposal;
};

type FormValues = {
  cost_proposal_type: CostProposalType;
  direct_labor_bid?: number;
  direct_labor_cost?: number;
  sub_odc_bid?: number;
  sub_odc_cost?: number;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: { proposal },
    hideForm,
  } = props;
  const {
    id,
    cost_proposal_type,
    opportunity,
    direct_labor_bid,
    direct_labor_cost,
    sub_odc_bid,
    sub_odc_cost,
  } = proposal;
  const defaultValues = {
    cost_proposal_type,
    ...(direct_labor_bid && { direct_labor_bid }),
    ...(direct_labor_cost && { direct_labor_cost }),
    ...(sub_odc_bid && { sub_odc_bid }),
    ...(sub_odc_cost && { sub_odc_cost }),
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { getValues, setValue, watch } = methods;
  const { onSubmit } = useResourceForm<FormValues, Proposal>({
    id,
    defaultValues,
    methods,
    onSubmitSuccess: handleSubmitSuccess,
    resourceData: {
      endpoint: "/api/proposals",
      resource: RESOURCE,
      singularName: "Proposal",
    },
  });
  const isBidCostProposalType =
    watch("cost_proposal_type") === CostProposalType.BID;
  const { mutate } = useSWRConfig();

  useEffect(() => {
    const values = getValues();
    const fields = [
      "direct_labor_bid",
      "direct_labor_cost",
      "sub_odc_bid",
      "sub_odc_cost",
    ];

    if (!isBidCostProposalType) {
      fields.forEach((field) => {
        if (values.hasOwnProperty(field)) {
          setValue(field as keyof FormValues, 0, {
            shouldDirty: true,
          });
        }
      });
    } else {
      fields.forEach((field) => {
        if (proposal!.hasOwnProperty(field)) {
          setValue(
            field as keyof FormValues,
            proposal![field as keyof FormValues],
            { shouldDirty: true }
          );
        }
      });
    }
  }, [getValues, isBidCostProposalType, proposal, setValue]);

  function handleSubmitSuccess() {
    hideForm();
    mutate(`/api/opportunities/${opportunity.id}`);
  }

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <SelectField
            label="Cost proposal type"
            name="cost_proposal_type"
            size="2"
          >
            {[
              CostProposalType.BID,
              CostProposalType.RATES,
              CostProposalType.NONE,
              CostProposalType.OTHER,
            ].map((cpt) => (
              <Option key={cpt} size="2" value={cpt}>
                {cpt}
              </Option>
            ))}
          </SelectField>

          {isBidCostProposalType && (
            <>
              <TextField
                autoFocus
                inputMode="numeric"
                label="Direct labor bid"
                min={0}
                name="direct_labor_bid"
                placeholder="5500"
                required
                startAdornment={<Text size="4">$</Text>}
                step={100}
                type="number"
              />

              <TextField
                inputMode="numeric"
                label="Direct labor cost"
                min={0}
                name="direct_labor_cost"
                placeholder="1800"
                required
                startAdornment={<Text size="4">$</Text>}
                step={100}
                type="number"
              />

              <TextField
                inputMode="numeric"
                label="Sub & ODC bid"
                min={0}
                name="sub_odc_bid"
                placeholder="4500"
                required
                startAdornment={<Text size="4">$</Text>}
                step={100}
                type="number"
              />

              <TextField
                inputMode="numeric"
                label="Sub & ODC cost"
                min={0}
                name="sub_odc_cost"
                placeholder="1500"
                required
                startAdornment={<Text size="4">$</Text>}
                step={100}
                type="number"
              />
            </>
          )}
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateProposal(props: { id: number; hideForm: () => void }) {
  const { data: opportunity } = useSWR(`/api/opportunities/${props.id}`);
  const { data: proposal } = useSWR(
    opportunity ? `/api/proposals/${opportunity.proposals[0].id}` : null
  );

  if (!proposal) {
    return null;
  }

  return <Form {...props} data={{ proposal }} />;
}
