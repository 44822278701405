import { NavigationTabs } from "@/components";

export function OpportunityNavigationTabs() {
  return (
    <NavigationTabs
      basePath="/opportunities"
      label="Opportunity navigation"
      navigationItems={[
        { label: "Opportunity", path: "" },
        { label: "CCRs", path: "/ccrs" },
      ]}
    />
  );
}
