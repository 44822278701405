import { useRouter } from "next/router";

import { useUser } from "@/hooks";

export function useProtectedRoute() {
  const router = useRouter();
  const user = useUser({
    redirectTo: `/sign-in?${new URLSearchParams({
      return_to: router.asPath,
    }).toString()}`,
  });

  return user;
}
