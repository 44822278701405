import type { PropsWithChildren } from "react";

import { ResourceSearchContext, useResourceSearchState } from "@/hooks";

export function ResourceSearchProvider(props: PropsWithChildren<{}>) {
  const resourceSearchState = useResourceSearchState();

  return (
    <ResourceSearchContext.Provider {...props} value={resourceSearchState} />
  );
}
