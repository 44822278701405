import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  AddResourceForm,
  Column,
  H4,
  Option,
  ResourceHeader,
  SelectField,
  TextField
} from "@/components";
import { useInitialClient, useResourceForm, useSheet } from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Client, ClientSector, ClientType } from "@/types/models";

const RESOURCE = Resource.CLIENTS;

type FormValues = {
  client_sector_id: number;
  client_type_id: number;
  organization_name: string;
};

type Data = { clientSectors: ClientSector[]; clientTypes: ClientType[] };

function Form(props: { data: Data }) {
  const { clientSectors, clientTypes } = props.data;
  const defaultValues = {
    client_sector_id: clientSectors[0].id,
    client_type_id: clientTypes[0].id,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSelected } = useInitialClient() ?? {};
  const { onSubmit } = useResourceForm<FormValues, Client>({
    defaultValues,
    methods,
    onSubmitSuccess: onSelected,
    resourceData: {
      endpoint: "/api/clients",
      resource: RESOURCE,
      singularName: "Client",
    },
  });
  const isSheet = Boolean(useSheet());

  return (
    <>
      {isSheet && (
        <ResourceHeader>
          <H4>Add Client</H4>
        </ResourceHeader>
      )}

      <AddResourceForm {...{ methods, onSubmit }}>
        <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
          <Column>
            <TextField
              autoCapitalize="words"
              autoFocus
              label="Organization name"
              name="organization_name"
              placeholder="Port of Seattle"
              required
            />

            <SelectField
              label="Client sector"
              name="client_sector_id"
              required
              size="2"
              valueAsNumber
            >
              {clientSectors.map((cs) => (
                <Option key={cs.id} size="2" value={cs.id}>
                  {cs.title}
                </Option>
              ))}
            </SelectField>

            <SelectField
              label="Client type"
              name="client_type_id"
              required
              size="2"
              valueAsNumber
            >
              {clientTypes.map((ct) => (
                <Option key={ct.id} size="2" value={ct.id}>
                  {ct.title}
                </Option>
              ))}
            </SelectField>
          </Column>
        </Flex>
      </AddResourceForm>
    </>
  );
}

export function AddClient() {
  const { data: clientSectors } = useSWR<ClientSector[]>("/api/client-sectors");
  const { data: clientTypes } = useSWR<ClientType[]>("/api/client-types");

  if (!clientSectors || !clientTypes) {
    return null;
  }

  return <Form data={{ clientSectors, clientTypes }} />;
}
