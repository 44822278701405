import { useRouter } from "next/router";

import { TabLink, Text } from "@/components";
import { css } from "@modulz/design-system";

const nav = css({
  display: "none",
  "@bp3": { ai: "flex-end", display: "flex", gap: "$2" },
});
const tabLink = css({ height: "25px!important" });
const text = css({ lineHeight: "25px!important" });

export function Nav() {
  const { asPath } = useRouter();
  const isCCRsActive = asPath.startsWith("/ccrs");
  const isClientsActive = asPath.startsWith("/clients");
  const isContactsActive = asPath.startsWith("/contacts");

  return (
    <nav aria-label="Main navigation" className={nav()}>
      <TabLink
        active={!isCCRsActive && !isClientsActive && !isContactsActive}
        className={tabLink()}
        href="/"
      >
        <Text className={text()} size="4">
          OPPORTUNITIES
        </Text>
      </TabLink>

      <TabLink active={isClientsActive} className={tabLink()} href="/clients">
        <Text className={text()} size="4">
          CLIENTS
        </Text>
      </TabLink>

      <TabLink active={isContactsActive} className={tabLink()} href="/contacts">
        <Text className={text()} size="4">
          CONTACTS
        </Text>
      </TabLink>
    </nav>
  );
}
