import NextLink from "next/link";
import { useRouter } from "next/router";

import { TabLink as DSTabLink } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type { LinkProps } from "next/link";

export function TabLink(
  props: LinkProps & ComponentPropsWithoutRef<typeof DSTabLink>
) {
  const router = useRouter();
  const {
    active = router.asPath.startsWith(props.href as string),
    children,
    href,
    ...rest
  } = props;

  return (
    <NextLink {...{ href }} passHref>
      <DSTabLink {...rest} {...{ active }}>
        {children}
      </DSTabLink>
    </NextLink>
  );
}
