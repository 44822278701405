import { useRouter } from "next/router";

import { FixedBottom, Link, Text } from "@/components";
import { hasAccountingRole, useUser } from "@/hooks";
import {
  Avatar,
  Box,
  css,
  IconButton,
  Link as DSLink,
  Tooltip
} from "@modulz/design-system";
import { ArrowUpIcon, Pencil1Icon, PlusIcon } from "@radix-ui/react-icons";

import type { ComponentPropsWithoutRef, MouseEvent, ReactNode } from "react";

import type { LinkProps } from "next/link";

const iconButton = css({
  variants: {
    fab: {
      true: {
        backgroundColor: "$hiContrast",
        borderRadius: "$round!important",
        color: "$loContrast",
      },
    },
  },

  defaultVariants: {
    fab: true,
  },
});
const strong = css({ fontWeight: 500 });

export function AddFab(
  props: ComponentPropsWithoutRef<typeof FixedBottom> &
    LinkProps &
    ComponentPropsWithoutRef<typeof DSLink> & { title?: string }
) {
  const { position, title, ...rest } = props;
  const user = useUser();
  const avatarEl = (
    <Avatar
      fallback={<PlusIcon height={25} width={25} />}
      size="5"
      variant="hiContrast"
    />
  );

  return hasAccountingRole(user) ? null : (
    <FixedBottom {...{ position }}>
      <Box style={{ right: 25 }}>
        <Link fab {...rest}>
          {title ? (
            <Tooltip content={title} side="left" sideOffset={25}>
              {avatarEl}
            </Tooltip>
          ) : (
            avatarEl
          )}
        </Link>
      </Box>
    </FixedBottom>
  );
}

export function CCRFab(
  props: ComponentPropsWithoutRef<typeof FixedBottom> &
    LinkProps &
    ComponentPropsWithoutRef<typeof DSLink> & { title?: string }
) {
  const { position = "2", title, ...rest } = props;
  const user = useUser();
  const avatarEl = (
    <Avatar fallback={<Text className={strong()}>+CCR</Text>} size="5" />
  );

  return hasAccountingRole(user) ? null : (
    <FixedBottom {...{ position }}>
      <Box style={{ right: 25 }}>
        <Link fab {...rest}>
          {title ? (
            <Tooltip content={title} side="left" sideOffset={25}>
              {avatarEl}
            </Tooltip>
          ) : (
            avatarEl
          )}
        </Link>
      </Box>
    </FixedBottom>
  );
}

export function Fab(
  props: ComponentPropsWithoutRef<typeof FixedBottom> &
    ComponentPropsWithoutRef<typeof IconButton> & {
      iconEl?: ReactNode;
      requiresAuth?: boolean;
    }
) {
  const {
    iconEl = <Pencil1Icon height={25} width={25} />,
    onClick,
    position,
    requiresAuth = true,
    title,
    ...rest
  } = props;
  const user = useUser();
  const isDisabled = requiresAuth && !user;
  const fabEl = (
    <IconButton
      className={iconButton()}
      css={{ backgroundColor: isDisabled ? "$slate6" : undefined }}
      onClick={isDisabled ? handleClick : onClick}
      size="4"
      variant="raised"
      {...rest}
    >
      {iconEl}
    </IconButton>
  );

  function handleClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  return hasAccountingRole(user) ? null : (
    <FixedBottom {...{ position }}>
      <Box style={{ right: 25 }}>
        {title ? (
          <Tooltip
            content={isDisabled ? `Sign in to ${title.toLowerCase()}` : title}
            side="left"
            sideOffset={25}
          >
            {fabEl}
          </Tooltip>
        ) : (
          fabEl
        )}
      </Box>
    </FixedBottom>
  );
}

export function ScrollToTopFab() {
  const { asPath, isReady, query } = useRouter();
  const user = useUser();
  const isCCRsRelationPage = asPath.split("?")[0].endsWith("/ccrs");

  function handleClick() {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }

  return !isReady || Number(query.st ?? 0) === 0 ? null : (
    <Fab
      iconEl={<ArrowUpIcon height={25} width={25} />}
      onClick={handleClick}
      position={hasAccountingRole(user) ? "1" : isCCRsRelationPage ? "2" : "3"}
      requiresAuth={false}
      title="Scroll to top"
    />
  );
}

export const UpdateFab = Fab;
