import Router from "next/router";

import { FullScreenDialogContent } from "@/components";
import { useResourceSearch } from "@/hooks";
import { css, Dialog, Flex, IconButton } from "@modulz/design-system";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

import type { PropsWithChildren, ReactNode } from "react";

const iconButton = css({ ml: "$2" });
const header = css({
  borderBottom: "1px solid $slate8",
  position: "sticky",
  py: "$2",
  top: 0,
});
const section = css({ height: "100%", pb: "$5", pt: "$2" });
const flex = css({ pr: "$5" });

function DialogContentHeader(props: PropsWithChildren<{}>) {
  function handleBack() {
    Router.back();
  }

  return (
    <header className={header()}>
      <Flex align="center" className={flex()} gap="1">
        <IconButton className={iconButton()} onClick={handleBack}>
          <ArrowLeftIcon color="gray" height={25} width={25} />
        </IconButton>
        {props.children}
      </Flex>
    </header>
  );
}

export function SearchDialog(props: PropsWithChildren<{ search: ReactNode }>) {
  const { children, search } = props;
  const { isDialogOpen } = useResourceSearch();

  function handleOpenChange() {
    if (isDialogOpen) {
      Router.back();
    } else {
      Router.push("#search");
    }
  }

  return (
    <Dialog onOpenChange={handleOpenChange} open={isDialogOpen}>
      <FullScreenDialogContent>
        <DialogContentHeader>{search}</DialogContentHeader>
        <section className={section()}>{children}</section>
      </FullScreenDialogContent>
    </Dialog>
  );
}
