import { useForm } from "react-hook-form";

import {
  AddResourceForm,
  AddResourceFormActions,
  ClientField,
  Column,
  H4,
  PhoneTextField,
  ResourceHeader,
  SelectInitialClient,
  SwitchField,
  TextField
} from "@/components";
import {
  useClientRelation,
  useInitialClient,
  useInitialContact,
  useResourceForm,
  useSheet
} from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Client, Contact } from "@/types/models";

const RESOURCE = Resource.CONTACTS;

type FormValues = {
  client_id: number;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  email: string;
  first_name: string;
  is_default: boolean;
  last_name: string;
  phone?: string;
  state?: string;
  title?: string;
  zip_code?: string;
};

function Form(props: { initialClient: Client }) {
  const { initialClient } = props;
  const { id: initialClientId } = initialClient;
  const defaultValues = { client_id: initialClientId, is_default: true };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSelected } = useInitialContact() ?? {};
  const { onSubmit } = useResourceForm<FormValues, Contact>({
    defaultValues,
    methods,
    onSubmitSuccess: onSelected,
    resourceData: {
      endpoint: "/api/contacts",
      resource: RESOURCE,
      singularName: "Contact",
    },
  });
  const client = useClientRelation<FormValues>({
    control: methods.control,
    defaultValue: initialClientId,
    fallbackData: initialClient,
  });
  const isSheet = Boolean(useSheet());

  return (
    <>
      {isSheet && (
        <ResourceHeader>
          <H4>Add Contact</H4>
        </ResourceHeader>
      )}

      <AddResourceForm {...{ methods, onSubmit }} defaultActions={false}>
        <Flex
          gap={{ "@initial": "1", "@bp1": isSheet ? "1" : "7" }}
          wrap="wrap"
        >
          <Column>
            <ClientField {...{ client }} />

            <SwitchField
              label="Default client contact?"
              name="is_default"
              required
            />
          </Column>

          <Column>
            <TextField
              autoCapitalize="words"
              autoFocus
              label="First name"
              name="first_name"
              placeholder="Alex"
              required
            />

            <TextField
              autoCapitalize="words"
              label="Last name"
              name="last_name"
              placeholder="Smith"
              required
            />

            <TextField
              autoCapitalize="none"
              inputMode="email"
              label="Email"
              name="email"
              placeholder="email@example.com"
              required
            />

            <TextField
              autoCapitalize="words"
              label="Title"
              name="title"
              placeholder="CEO"
            />

            <PhoneTextField
              label="Phone"
              name="phone"
              placeholder="555-555-5555"
              type="tel"
            />
          </Column>

          <Column>
            <TextField
              autoCapitalize="words"
              label="Address line 1"
              name="address_line_1"
              placeholder="123 4th St."
            />

            <TextField
              autoCapitalize="words"
              label="Address line 2"
              name="address_line_2"
              placeholder="Suite 456"
            />

            <TextField
              autoCapitalize="words"
              label="City"
              name="city"
              placeholder="Seattle"
            />

            <TextField
              autoCapitalize="characters"
              label="State"
              name="state"
              placeholder="WA"
            />

            <TextField
              inputMode="numeric"
              label="Zip code"
              name="zip_code"
              placeholder="12345"
            />
          </Column>
        </Flex>

        <AddResourceFormActions />
      </AddResourceForm>
    </>
  );
}

export function AddContact() {
  const { initialClient, initialClientId } = useInitialClient() ?? {};

  if (initialClientId && !initialClient) {
    return null;
  }

  return initialClient ? (
    <Form {...{ initialClient }} />
  ) : (
    <SelectInitialClient />
  );
}
