import { HttpMethod } from "@/lib/fetch-json";

export class FetchError extends Error {
  public data: Record<string, any>;
  public response: Response;
  public statusText: string;

  constructor(
    statusText: string,
    data: Record<string, any>,
    response: Response
  ) {
    super(statusText);
    Object.setPrototypeOf(this, new.target.prototype);
    this.data = data;
    this.response = response;
    this.statusText = statusText;
  }
}

export class MethodNotAllowedError extends Error {
  public statusCode: number;

  constructor(method: HttpMethod) {
    super(`Method (${method}) not allowed`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.statusCode = 405;
  }
}
