import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { Checkbox, css, Flex, Text, TextField } from "@modulz/design-system";

import type { ChangeEvent } from "react";

import type {
  EvaluationPoint,
  EvaluationPointOption,
  Opportunity,
} from "@/types/models";

const DEFAULT_ESTIMATED_LABOR_HOURS = 5;

const flex = css({ mb: "$5", mt: "$3" });
const strong = css({ fontWeight: 500 });
const column = css({ maxWidth: 335 });

type EstimatedLaborHoursTextFieldProps = {
  inputRef: any;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: number | null;
};
function EstimatedLaborHoursTextField(
  props: EstimatedLaborHoursTextFieldProps
) {
  const { inputRef, name, onChange, value, ...rest } = props;

  return (
    <TextField
      {...rest}
      {...{ name, onChange }}
      ref={(e) => {
        inputRef(e);
      }}
      autoFocus
      id={name}
      min={1}
      size="2"
      step={1}
      type="number"
      value={value ?? ""}
    />
  );
}

type ControlledEstimatedLaborHoursTextFieldProps = {
  initialValue: number | null;
  name: string;
  onValueChange: (value: number) => void;
};

function ControlledEstimatedLaborHoursTextField(
  props: ControlledEstimatedLaborHoursTextFieldProps
) {
  const { initialValue, name, onValueChange } = props;
  const { control, setValue } = useFormContext();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue === null) {
      setValue(name, DEFAULT_ESTIMATED_LABOR_HOURS, { shouldDirty: true });
    }

    setIsReady(true);

    return () => {
      setValue(name, initialValue, { shouldDirty: true });
    };
  }, [initialValue, name, setIsReady, setValue]);

  return isReady ? (
    <Controller
      {...{ control, name }}
      render={({ field }) => {
        const { onChange, ref, ...rest } = field;

        function handleChange(event: ChangeEvent<HTMLInputElement>) {
          const currentTargetValue = event.currentTarget.value;
          const value =
            currentTargetValue === "" ? null : Number(currentTargetValue);

          onChange(value);
          onValueChange(value ?? 0);
        }

        return (
          <EstimatedLaborHoursTextField
            {...rest}
            inputRef={ref}
            onChange={handleChange}
          />
        );
      }}
      rules={{ required: "Estimated labor hours is required" }}
    />
  ) : null;
}

export function getProposalEffortValue({
  isBilledToClient,
  anticipatedContractAmount,
  winProbability,
  estimatedLaborHours,
  evaluationPointOptions,
}: {
  isBilledToClient: boolean;
  anticipatedContractAmount: number;
  winProbability: number;
  estimatedLaborHours: number;
  evaluationPointOptions: EvaluationPointOption[];
}) {
  let newValue;

  if (isBilledToClient) {
    newValue = evaluationPointOptions[0].id;
  } else if (anticipatedContractAmount) {
    const weightedAmount = anticipatedContractAmount * winProbability * 0.025;
    const estimatedLaborCost = estimatedLaborHours * 100;

    newValue =
      evaluationPointOptions[weightedAmount > estimatedLaborCost ? 1 : 2].id;
  }

  return newValue;
}

type Props = {
  initialEstimatedLaborHours: number | null;
  evaluationPoint: EvaluationPoint;
  name: string;
  opportunity: Opportunity;
};

export function ProposalEffortField(props: Props) {
  const { initialEstimatedLaborHours, evaluationPoint, name, opportunity } =
    props;
  const estimatedLaborHoursInputName = "estimated_labor_hours";
  const { control, setValue, watch } = useFormContext();
  const watchValue = watch(name);
  const watchWinProbability = watch("win_probability");
  const { description, evaluation_point_options, title } = evaluationPoint;
  const noneValue = evaluation_point_options[0].id;
  const evaluationPointOptionsById = Object.fromEntries(
    evaluation_point_options.map((epo) => [epo.id, epo])
  );
  const { anticipated_contract_amount } = opportunity;
  const isBilledToClient = watchValue === noneValue;

  function handleEstimatedLaborHoursChange(estimatedLaborHours: number) {
    const newValue = getProposalEffortValue({
      isBilledToClient,
      anticipatedContractAmount: anticipated_contract_amount,
      winProbability: watchWinProbability,
      estimatedLaborHours,
      evaluationPointOptions: evaluation_point_options,
    });

    if (newValue !== watchValue) {
      setValue(name, newValue, { shouldDirty: true });
    }
  }

  return (
    <FormField {...{ description, name }} label={title} required>
      <Controller
        {...{ control, name }}
        render={({ field }) => {
          const { onChange, value } = field;

          function handleCheckboxChange(checked: boolean) {
            if (checked) {
              onChange(noneValue);
            } else {
              onChange(
                getProposalEffortValue({
                  isBilledToClient: false,
                  anticipatedContractAmount: anticipated_contract_amount,
                  winProbability: watchWinProbability,
                  estimatedLaborHours: DEFAULT_ESTIMATED_LABOR_HOURS,
                  evaluationPointOptions: evaluation_point_options,
                })
              );
            }
          }

          return (
            <Flex className={flex()} direction="column" gap="3">
              <Text className={strong()} size="5">
                {evaluationPointOptionsById[value].title}
              </Text>
              <Flex align="center" gap="3">
                <Checkbox
                  id="is-billed-to-client"
                  checked={isBilledToClient}
                  onCheckedChange={handleCheckboxChange}
                  size="2"
                />
                <label htmlFor="is-billed-to-client">Billed to client?*</label>
              </Flex>
            </Flex>
          );
        }}
      />
      {!isBilledToClient && (
        <Flex className={column()} direction="column" gap="2">
          <label htmlFor={estimatedLaborHoursInputName}>
            Estimated labor hours*
          </label>
          <ControlledEstimatedLaborHoursTextField
            initialValue={initialEstimatedLaborHours}
            name={estimatedLaborHoursInputName}
            onValueChange={handleEstimatedLaborHoursChange}
          />
        </Flex>
      )}
    </FormField>
  );
}
