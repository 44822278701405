import Image from "next/image";
import { useRouter } from "next/router";

import {
  H3,
  H4,
  Header,
  Link,
  Main,
  Nav,
  NavigationMenu,
  ThemeChanger
} from "@/components";
import { useNavigationMenu, useUser } from "@/hooks";
import supabaseLogoImg from "@/public/images/supabase-logo-icon.png";
import {
  Box,
  css,
  Flex,
  IconButton,
  Link as DSLink
} from "@modulz/design-system";
import { Cross1Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";

import type { PropsWithChildren } from "react";

const box = css({ display: "none", "@bp1": { display: "block" } });
const leftFlex = css({ height: 25 });
const rightFlex = css({ height: 31 });
const h4 = css({
  color: "$blue11!important",
  fontWeight: 400,
  lineHeight: "25px!important",
});
const flex = css({
  width: 83,
  "@media (max-width: 320px)": { display: "none!important" },
});
const iconButton = css({ "@bp3": { display: "none!important" } });
const iconProps = { height: 25, width: 25 };

function LinkHeading(props: PropsWithChildren<{}>) {
  return <H4 className={h4()}>{props.children}</H4>;
}

export function DefaultLayout(props: PropsWithChildren<{}>) {
  const user = useUser();
  const { asPath } = useRouter();
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useNavigationMenu();

  function toggleNavigationMenu() {
    setIsNavigationMenuOpen(!isNavigationMenuOpen);
  }

  return (
    <>
      <Header>
        <Flex align="center" className={leftFlex()} gap="5">
          <Link href="/">
            <H3>SES Pipeline</H3>
          </Link>

          <Box className={box()}>
            <ThemeChanger />
          </Box>
        </Flex>

        <Flex
          align="end"
          className={rightFlex()}
          gap={{ "@initial": "5", "@bp1": "8" }}
        >
          {user && <Nav />}

          <Flex align="end" className={flex()} justify="end">
            {user ? (
              <DSLink href="/sign-out">
                <LinkHeading>SIGN OUT</LinkHeading>
              </DSLink>
            ) : user === null ? (
              <Link
                css={{
                  visibility: asPath.startsWith("/sign-in")
                    ? "hidden"
                    : "visible",
                }}
                href={`/sign-in?${new URLSearchParams({
                  return_to: asPath,
                }).toString()}`}
                thick
              >
                <LinkHeading>SIGN IN</LinkHeading>
              </Link>
            ) : null}
          </Flex>

          <IconButton className={iconButton()} onClick={toggleNavigationMenu}>
            {isNavigationMenuOpen ? (
              <Cross1Icon {...iconProps} />
            ) : (
              <HamburgerMenuIcon {...iconProps} />
            )}
          </IconButton>
        </Flex>
      </Header>

      <Main>{isNavigationMenuOpen ? <NavigationMenu /> : props.children}</Main>
    </>
  );
}
