import { useRouter } from "next/router";
import { useState } from "react";

export function useInitialInfiniteSize() {
  const {
    query: { pages },
  } = useRouter();
  const [initialSize] = useState<number | undefined>(
    pages ? Number(pages) : undefined
  );

  return initialSize;
}
