import {
  AddContact,
  InitialClientProvider,
  ResourceSheet,
  ResourceSheetContent
} from "@/components";
import { Button, css, SheetTrigger } from "@modulz/design-system";

const button = css({
  alignSelf: "center!important",
  flexShrink: "1!important",
});

export function AddContactButton(props: { clientId?: number }) {
  return (
    <ResourceSheet>
      <ResourceSheetContent>
        <InitialClientProvider {...props}>
          <AddContact />
        </InitialClientProvider>
      </ResourceSheetContent>
      <SheetTrigger asChild>
        <Button className={button()} size="2">
          Add Contact
        </Button>
      </SheetTrigger>
    </ResourceSheet>
  );
}
