import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { phoneMasked } from "@/lib/utils";
import { textField } from "@/styles";
import { TextField } from "@modulz/design-system";

import type { ChangeEvent, ComponentPropsWithoutRef } from "react";

type Props = {
  defaultValue?: string;
  label: string;
  name: string;
  required?: boolean;
  requiredMessage?: string;
};

export function PhoneTextField(
  props: ComponentPropsWithoutRef<typeof FormField> &
    ComponentPropsWithoutRef<typeof TextField> &
    Props
) {
  const {
    defaultValue = "",
    label,
    name,
    required = false,
    requiredMessage = `${label} is required`,
    size = 2,
    ...textFieldProps
  } = props;
  const { control } = useFormContext();

  return (
    <FormField {...{ label, name, required }}>
      <Controller
        {...{ control, defaultValue, name }}
        render={({ field }) => {
          const { onChange, value, ...rest } = field;

          function handleChange(event: ChangeEvent<HTMLInputElement>) {
            const currentValue = event.currentTarget.value;

            if (!currentValue) {
              return onChange("");
            }

            phoneMasked.resolve(currentValue);
            onChange(phoneMasked.unmaskedValue);
          }

          return (
            <TextField
              {...rest}
              {...textFieldProps}
              {...{ name, size }}
              className={textField()}
              onChange={handleChange}
              type="tel"
              value={value ? phoneMasked.resolve(value) : ""}
            />
          );
        }}
        rules={{
          maxLength: 11,
          minLength: { message: "At least 8 digits required", value: 8 },
          ...(required && { required: `${label} is required` }),
        }}
      />
    </FormField>
  );
}
