import "@/shims";
import { ThemeProvider } from "next-themes";
import Head from "next/head";
import { Toaster } from "react-hot-toast";
import { SWRConfig } from "swr";

import { NavigationMenuProvider } from "@/components";
import { DefaultLayout } from "@/layouts";
import { fetchJSON } from "@/lib/fetch-json";
import {
  darkTheme,
  DesignSystemProvider,
  globalCss,
} from "@modulz/design-system";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
const globalStyles = globalCss({
  body: {
    backgroundColor: "$loContrast",
    margin: 0,
    overflowY: "scroll",
  },

  "body, button, option, select": {
    fontFamily:
      "Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, system-ui, sans-serif",
  },

  a: {
    color: "inherit",
    textDecoration: "none",
  },

  svg: { display: "block", verticalAlign: "middle" },

  ul: { listStyleType: "none", p: 0 },

  "*": {
    boxSizing: "border-box",
  },
});

const description = "SES Pipeline";
const socialTitle = "SES Pipeline";
const siteName = "SES Pipeline";
const url = "https://pipeline.soundearthinc.com";
const imageUrl = "/images/pipeline-soundearthinc-com.png";

type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};

type MyAppProps = AppProps & {
  Component: Page;
};

function MyApp({ Component, pageProps }: MyAppProps) {
  globalStyles();
  const getLayout =
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <>
      <Head>
        <title>{socialTitle}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="title" content={socialTitle} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={socialTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${url}${imageUrl}`} />
        <meta property="og:site_name" content={`${siteName}`} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={socialTitle} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={`${url}${imageUrl}`} />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <SWRConfig
        value={{
          fetcher: fetchJSON,
          onError: (err) => {
            console.error(err);

            if (err?.response?.status === 401) {
              window.location.href = "/api/sign-out";
            }
          },
          revalidateIfStale: false,
        }}
      >
        <DesignSystemProvider>
          <ThemeProvider
            disableTransitionOnChange
            attribute="class"
            value={{ light: "light-theme", dark: darkTheme.toString() }}
            defaultTheme="light"
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <NavigationMenuProvider>
                {getLayout(<Component {...pageProps} />)}
                <Toaster position="bottom-center" />
              </NavigationMenuProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </DesignSystemProvider>
      </SWRConfig>
    </>
  );
}
export default MyApp;
