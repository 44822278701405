import { useRouter } from "next/router";
import useSWR from "swr";

import { useProtectedRoute } from "@/hooks";

import type { Client } from "@/types/models";
export function useClient() {
  const user = useProtectedRoute();
  const router = useRouter();
  const {
    asPath,
    isReady,
    query: { id, client_id },
  } = router;
  const clientId = id ?? client_id;
  const { data: client } = useSWR<Client>(
    user && isReady && clientId ? `/api/clients/${clientId}` : null
  );

  return {
    asPath,
    client,
    isLoading: !isReady || (isReady && clientId && !client),
  };
}
