import toast from "react-hot-toast";
import { useSWRConfig } from "swr";

import { H4 } from "@/components";
import { patch } from "@/lib/fetch-json";
import { ApprovalType, Decision } from "@/types/models";
import { Box, Button, css, Flex } from "@modulz/design-system";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";

import type * as Models from "@/types/models";

const h4 = css({ mb: "$3" });
const box = css({ mr: "$1" });
const iconProps = { height: 25, width: 25 };
const icons = {
  [ApprovalType.GO_NO_GO]: {
    [Decision.APPROVED]: <CheckCircledIcon {...iconProps} color="green" />,
    [Decision.REJECTED]: <CrossCircledIcon {...iconProps} color="red" />,
  },
  [ApprovalType.PROPOSAL]: {
    [Decision.APPROVED]: <CheckCircledIcon {...iconProps} color="green" />,
    [Decision.REJECTED]: <CrossCircledIcon {...iconProps} color="red" />,
  },
};

type Props = {
  approval: Models.Approval;
};

export function Approval(props: Props) {
  const {
    approval: { id, opportunity_id, decision, type },
  } = props;
  const isPending = decision === Decision.PENDING;
  const { mutate } = useSWRConfig();

  async function patchApproval(decision: Models.Decision) {
    await patch(`/api/approvals/${id}`, { decision });
    mutate(`/api/opportunities/${opportunity_id}`);

    if (decision === Decision.APPROVED) {
      toast.success(`${type} Approved`);
    } else {
      toast.error(`${type} Rejected`);
    }
  }

  function handleApprove() {
    return patchApproval(Decision.APPROVED);
  }

  function handleReject() {
    return patchApproval(Decision.REJECTED);
  }

  if (!isPending) {
    return null;
  }

  return (
    <>
      <H4 className={h4()}>Approval Requested</H4>
      <Flex align="center" gap="2">
        <Button onClick={handleApprove} size="2" variant="blue">
          <Box className={box()}>{icons[type][Decision.APPROVED]}</Box>
          Go
        </Button>
        <Button onClick={handleReject} size="2" variant="blue">
          <Box className={box()}>{icons[type][Decision.REJECTED]}</Box>
          No Go
        </Button>
      </Flex>
    </>
  );
}
