import { useCallback } from "react";

import {
  AddClientButton,
  ClientListItem,
  ResourceList,
  ResourceListEmptyState
} from "@/components";
import {
  useInfiniteClients,
  useInfiniteList,
  useInitialClient,
  useResourceSearch
} from "@/hooks";
import { Flex } from "@modulz/design-system";

import type { Client } from "@/types/models";

type Props = {
  onSelected?: (client: Client) => void;
};

export function Clients(props: Props) {
  const { isDialogOpen } = useResourceSearch() ?? {};
  const initialClient = useInitialClient();
  const onSelected = props.onSelected ?? initialClient?.onSelected;
  const itemContent = useCallback(
    function itemContent(_, client) {
      return <ClientListItem {...{ client, onSelected }} />;
    },
    [onSelected]
  );
  const response = useInfiniteClients();
  const infiniteListProps = useInfiniteList<Client>({
    itemContent,
    response,
  });

  return infiniteListProps.isEmpty ? (
    <Flex direction="column" gap="5">
      <ResourceListEmptyState>No Clients</ResourceListEmptyState>
      {isDialogOpen && <AddClientButton />}
    </Flex>
  ) : (
    <ResourceList<Client> {...infiniteListProps} />
  );
}
