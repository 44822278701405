import { useCallback, useState } from "react";

import type { ComponentType } from "react";

export function useActiveForm<T extends string>({
  defaultFormId,
  formComponents,
}: {
  defaultFormId: T;
  formComponents: Record<
    T,
    ComponentType<{ id: number; hideForm: () => void }>
  >;
}) {
  const [activeFormId, setActiveFormId] = useState<T | undefined>();
  const showDefaultForm = useCallback(
    function showDefaultForm() {
      setActiveFormId(defaultFormId);
    },
    [setActiveFormId, defaultFormId]
  );
  const showForm = useCallback(
    function showForm(formId: T) {
      setActiveFormId(formId);
    },
    [setActiveFormId]
  );
  const hideForm = useCallback(
    function hideForm() {
      setActiveFormId(undefined);
      window.scrollTo({ top: 0 });
    },
    [setActiveFormId]
  );

  return {
    ActiveForm: formComponents[activeFormId ?? defaultFormId],
    activeFormId,
    hideForm,
    showDefaultForm,
    showForm,
  };
}
