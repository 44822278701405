import { useWatch } from "react-hook-form";
import useSWR from "swr";

import type { Control, FieldPath, Path, PathValue } from "react-hook-form";
import type { Contact } from "@/types/models";

export function useContactRelation<T>({
  control,
  defaultValue,
  fallbackData,
  name = "contact_id" as Path<T>,
}: {
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
  fallbackData?: Contact;
  name?: FieldPath<T>;
}): Contact | undefined {
  const contactId = useWatch<T>({ control, defaultValue, name });
  const { data: contact } = useSWR(
    contactId ? `/api/contacts/${contactId}` : null,
    { fallbackData }
  );

  return contact;
}
