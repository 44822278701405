import { useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";

import {
  AddResourceForm,
  AddSubmitButton,
  Column,
  TextField
} from "@/components";
import { useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Button, css, Flex } from "@modulz/design-system";

import type { Opportunity, Proposal } from "@/types/models";

const RESOURCE = Resource.PROPOSALS;
const flex = css({ mt: "$5" });

type Data = {
  opportunity: Opportunity;
};

type FormValues = {
  accounting_number: string;
  opportunity_id: number;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: { opportunity },
    hideForm,
  } = props;
  const { id: opportunity_id } = opportunity;
  const defaultValues = { opportunity_id };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Proposal>({
    defaultValues,
    methods,
    onSubmitSuccess: handleSubmitSuccess,
    resourceData: {
      endpoint: "/api/proposals",
      resource: RESOURCE,
      singularName: "Proposal Number",
    },
  });
  const { mutate } = useSWRConfig();

  function handleSubmitSuccess() {
    hideForm();
    mutate(`/api/opportunities/${opportunity_id}`);
  }

  return (
    <AddResourceForm {...{ methods, onSubmit }} defaultActions={false}>
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <TextField
            autoFocus
            label="Proposal number"
            name="accounting_number"
            placeholder="proposal-12345"
            required
          />
        </Column>
      </Flex>

      <Flex align="center" className={flex()} gap="5">
        <Button onClick={hideForm} size="2" tabIndex={-1} type="button">
          Cancel
        </Button>

        <AddSubmitButton />
      </Flex>
    </AddResourceForm>
  );
}

export function AddProposal(props: { id: number; hideForm: () => void }) {
  const { data: opportunity } = useSWR(`/api/opportunities/${props.id}`);

  if (!opportunity) {
    return null;
  }

  return <Form {...props} data={{ opportunity }} />;
}
