import { useRouter } from "next/router";

import { Link, ThemeChanger } from "@/components";
import { useMedia, useUser } from "@/hooks";
import { css, Flex, Link as DSLink } from "@modulz/design-system";

const defaultNavigationItems = [
  { label: "OPPORTUNITIES", path: "/" },
  { label: "CLIENTS", path: "/clients" },
  { label: "CONTACTS", path: "/contacts" },
];
const flex = css({ mt: "$5" });
const ul = css({
  ai: "center",
  display: "flex",
  fd: "column",
  gap: "$7",
});
const li = css({
  fontSize: "$6",
  fontWeight: 500,
  variants: { small: { true: { fontSize: "$4", mt: "$5" } } },
});

export type NavigationItem = {
  label: string;
  path: string;
};

export function NavigationMenu() {
  const router = useRouter();
  const isNarrow = useMedia("(max-width: 320px)");
  const user = useUser();
  const basePath = "";

  return (
    <Flex align="center" className={flex()} direction="column" gap="7">
      <ThemeChanger />

      <ul className={ul()}>
        {defaultNavigationItems.map(({ label, path }) => (
          <li className={li()} key={path}>
            <Link
              href={basePath ? `${basePath}/${router.query.id}${path}` : path}
              replace
            >
              {label}
            </Link>
          </li>
        ))}

        {isNarrow && user !== undefined && (
          <li className={li({ small: true })}>
            {user ? (
              <DSLink href="/sign-out">SIGN OUT</DSLink>
            ) : (
              <Link href="/sign-in" replace>
                SIGN IN
              </Link>
            )}
          </li>
        )}
      </ul>
    </Flex>
  );
}
