import { formatISOWithOptions } from "date-fns/fp";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  Column,
  DatePicker,
  Option,
  SelectField,
  UpdateResourceForm
} from "@/components";
import { useResourceForm } from "@/hooks";
import { SelectValue } from "@/types/misc";
import { ApprovalType, Decision, OpportunityStatus } from "@/types/models";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Opportunity } from "@/types/models";

const RESOURCE = Resource.OPPORTUNITIES;
const assignableStatusesByProposalApproval = {
  [true.toString()]: [
    OpportunityStatus.AWARDED,
    OpportunityStatus.DROPPED,
    OpportunityStatus.LOST,
  ],
  [false.toString()]: [OpportunityStatus.DROPPED],
};

type FormValues = {
  award_date?: string | null;
  status: OpportunityStatus | SelectValue;
};

type Data = {
  opportunity: Opportunity;
};

function Form(props: { id: number; data: Data; hideForm: () => void }) {
  const {
    data: { opportunity },
    hideForm,
  } = props;
  const { id, approvals, award_date, status } = opportunity;
  const isProposalApproved = approvals.some(
    ({ decision, type }) =>
      type === ApprovalType.PROPOSAL && decision === Decision.APPROVED
  );
  const assignableStatuses =
    assignableStatusesByProposalApproval[isProposalApproved.toString()];
  const defaultAssignableStatus = assignableStatuses[0];
  const defaultValues = {
    award_date: award_date ?? null,
    status: assignableStatuses.includes(status) ? status : SelectValue.EMPTY,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { setValue, watch } = methods;
  const { onSubmit } = useResourceForm<FormValues, Opportunity>({
    id,
    methods,
    onSubmitSuccess: hideForm,
    resourceData: {
      endpoint: "/api/opportunities",
      resource: RESOURCE,
      singularName: "Opportunity",
    },
  });
  const watchAwardDate = watch("award_date");
  const watchStatus = watch("status");
  const isAwardedStatus = watchStatus === OpportunityStatus.AWARDED;

  useEffect(() => {
    if (watchStatus === SelectValue.EMPTY) {
      setValue("status", defaultAssignableStatus, { shouldDirty: true });
    }
  }, [defaultAssignableStatus, setValue, watchStatus]);

  useEffect(() => {
    if (isAwardedStatus && !watchAwardDate) {
      setValue(
        "award_date",
        formatISOWithOptions({ representation: "date" }, new Date()),
        { shouldDirty: true }
      );
    }
  }, [isAwardedStatus, setValue, watchAwardDate]);

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <SelectField label="Status" name="status" size="2">
            {assignableStatuses.map((status) => (
              <Option key={status} size="2" value={status}>
                {status}
              </Option>
            ))}
          </SelectField>

          {isAwardedStatus && (
            <DatePicker label="Award date" name="award_date" required />
          )}
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateOpportunityStatus(props: {
  id: number;
  hideForm: () => void;
}) {
  const { data: opportunity } = useSWR<Opportunity>(
    `/api/opportunities/${props.id}`
  );

  if (!opportunity) {
    return null;
  }

  return <Form {...props} data={{ opportunity }} />;
}
