import { Link, Text } from "@/components";
import { useCacheResource, useResourceSearch } from "@/hooks";
import { Resource } from "@/types/resources";
import { Button, Flex } from "@modulz/design-system";

import type { Contact } from "@/types/models";

type Props = { contact: Contact; onSelected?: (contact: Contact) => void };

export function ContactListItem(props: Props) {
  const { contact, onSelected } = props;
  const {
    id,
    client: { id: clientId, organization_name: clientOrganizationName },
    full_name,
  } = contact;
  const primaryTextEl = <Text size="4">{full_name}</Text>;
  const { isDialogOpen } = useResourceSearch() ?? {};

  useCacheResource({ id, data: contact, resource: Resource.CONTACTS });
  useCacheResource({
    id: clientId,
    data: contact.client,
    resource: Resource.CLIENTS,
  });

  function handleSelected() {
    if (onSelected) {
      onSelected(contact);
    }
  }

  return (
    <>
      <Flex align="start" direction="column" gap="1">
        {onSelected ? (
          primaryTextEl
        ) : (
          <Link href={`/contacts/${id}`} replace={isDialogOpen} variant="blue">
            {primaryTextEl}
          </Link>
        )}
        <Text variant="gray">{clientOrganizationName}</Text>
      </Flex>

      {onSelected && (
        <Button onClick={handleSelected} type="button" variant="blue">
          SELECT
        </Button>
      )}
    </>
  );
}
