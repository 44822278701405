import { useTheme } from "next-themes";

import { useIsBrowser } from "@/hooks";
import { css, Flex, IconButton, Switch, Tooltip } from "@modulz/design-system";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

const flex = css({ color: "$hiContrast" });
const iconProps = { height: 25, width: 25 };

export function ThemeChanger() {
  const isBrowser = useIsBrowser();
  const { theme, setTheme } = useTheme();
  const isDarkTheme = theme === "dark";

  function handleCheckedChange() {
    setTheme(isDarkTheme ? "light" : "dark");
  }

  function handleDarkChecked() {
    setTheme("dark");
  }

  function handleLightChecked() {
    setTheme("light");
  }

  if (!isBrowser) {
    return null;
  }

  return (
    <Flex align="center" className={flex()}>
      <IconButton onClick={handleLightChecked} size="2">
        <Tooltip content="Light theme">
          <SunIcon {...iconProps} />
        </Tooltip>
      </IconButton>

      <Switch
        checked={isDarkTheme}
        onCheckedChange={handleCheckedChange}
        size="2"
      />

      <IconButton onClick={handleDarkChecked} size="2">
        <Tooltip content="Dark theme">
          <MoonIcon {...iconProps} />
        </Tooltip>
      </IconButton>
    </Flex>
  );
}
