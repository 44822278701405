import { useFormContext } from "react-hook-form";

import {
  AddContactButton,
  Contacts,
  FormField,
  H4,
  ResourceHeader,
  ResourceSearchForm,
  ResourceSearchProvider,
  ResourceSheet,
  ResourceSheetContent,
  Text
} from "@/components";
import { useSheet } from "@/hooks";
import { Flex, IconButton, SheetTrigger } from "@modulz/design-system";
import { Pencil1Icon } from "@radix-ui/react-icons";

import type { Contact } from "@/types/models";

function SelectContact(props: { clientId?: number; name: string }) {
  const { clientId, name } = props;
  const { setValue } = useFormContext();
  const { onOpenChange } = useSheet();

  function handleSelected(contact: Contact) {
    setValue(name, contact.id);
    onOpenChange(false);
  }

  return <Contacts {...{ clientId }} onSelected={handleSelected} />;
}

function SelectContactField(props: { clientId?: number; name: string }) {
  const { clientId, name } = props;

  return (
    <ResourceSearchProvider>
      <ResourceSheet>
        <ResourceSheetContent>
          <ResourceHeader>
            <H4>Select Contact</H4>
            <ResourceSearchForm placeholder="Search Contacts..." />
            <AddContactButton {...{ clientId }} />
          </ResourceHeader>

          <SelectContact {...{ clientId, name }} />
        </ResourceSheetContent>
        <SheetTrigger asChild>
          <IconButton>
            <Pencil1Icon height={20} width={20} />
          </IconButton>
        </SheetTrigger>
      </ResourceSheet>
    </ResourceSearchProvider>
  );
}

type Props = {
  clientId?: number;
  contact?: Contact;
  name?: string;
};

export function ContactField(props: Props) {
  const { clientId, contact, name = "contact_id" } = props;

  return (
    <FormField {...{ name }} label="Contact" selectable={false} required>
      <Flex align="center" gap="3">
        <Text size="4">{contact?.full_name ?? "..."}</Text>
        <SelectContactField {...{ clientId, name }} />
      </Flex>
    </FormField>
  );
}
