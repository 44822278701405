import { SheetProvider } from "@/components";
import { useSheet } from "@/hooks";
import { Sheet as DSSheet } from "@modulz/design-system";

import type { PropsWithChildren } from "react";

function Sheet(props: PropsWithChildren<{}>) {
  const sheet = useSheet();
  return <DSSheet {...props} {...sheet} />;
}

export function ResourceSheet(props: PropsWithChildren<{}>) {
  return (
    <SheetProvider>
      <Sheet {...props} />
    </SheetProvider>
  );
}
