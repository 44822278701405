import useSWRInfinite from "swr/infinite";

import { useInitialInfiniteSize, useResourceSearch } from "@/hooks";
import { getQueryString } from "@/lib/fetch-json";

import type { Client } from "@/types/models";

const PAGE_SIZE = 100;

export function useInfiniteClients() {
  const { value } = useResourceSearch() ?? {};
  const initialSize = useInitialInfiniteSize();
  const { data, error, isValidating, mutate, setSize, size } = useSWRInfinite<
    Client[]
  >(
    (index) => {
      return `/api/clients${getQueryString({
        $limit: PAGE_SIZE,
        $skip: index * PAGE_SIZE,
        ...(value && { organization_name: { $like: `%${value}%` } }),
      })}`;
    },
    { initialSize, revalidateIfStale: true }
  );
  const isEmpty = data?.[0]?.length === 0;

  return {
    data,
    error,
    isEmpty,
    isReachingEnd:
      isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE),
    isValidating,
    mutate,
    nextPage: () => setSize(size + 1),
    refresh: mutate,
    setSize,
    size,
  };
}
