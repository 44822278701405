import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useSWR, { useSWRConfig } from "swr";

import {
  Column,
  FormField,
  H4,
  Link,
  ResourceHeader,
  Text,
  UpdateResourceForm
} from "@/components";
import { useSheet } from "@/hooks/use-sheet";
import { post, remove } from "@/lib/fetch-json";
import { ApplicationRole, ApprovalType } from "@/types/models";
import { Resource } from "@/types/resources";
import { Checkbox, css, Flex } from "@modulz/design-system";

import type {
  Approval,
  Opportunity,
  Proposal,
  RequiredApprover,
  User,
} from "@/types/models";

const RESOURCE = Resource.APPROVALS;
const linkFlex = css({ height: 35 });

type FormValues = {
  additional_approver_user_ids: string[];
  opportunity_id: number;
  type: ApprovalType;
};

type Data = {
  opportunity: Opportunity;
  proposal?: Proposal;
  requiredApprovers: RequiredApprover[];
  users: User[];
};

function Form(props: { id: number; data: Data }) {
  const {
    data: { opportunity, proposal, requiredApprovers, users },
  } = props;
  const { id: opportunity_id, approvals, client, title } = opportunity;
  const requiredApproverUserIds = requiredApprovers.map((ra) => ra.user_id);
  const approvalType = proposal?.id
    ? ApprovalType.PROPOSAL
    : ApprovalType.GO_NO_GO;
  const additionalApproverUserIds = approvals
    .filter((a) => a.type === approvalType)
    .map((a) => a.user_id);
  const defaultValues = {
    additional_approver_user_ids: additionalApproverUserIds,
    opportunity_id,
    type: approvalType,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const sheet = useSheet();
  const isSheet = Boolean(sheet);
  const { onOpenChange } = sheet;
  const { mutate } = useSWRConfig();

  function hideForm() {
    onOpenChange(false);
  }

  async function onSubmit(data: FormValues) {
    const { additional_approver_user_ids } = data;

    try {
      onSubmitSuccess(
        (await Promise.all([
          ...(additional_approver_user_ids ?? [])
            .filter((userId) => !additionalApproverUserIds.includes(userId))
            .map((user_id) =>
              post<Approval>("/api/approvals", {
                opportunity_id,
                user_id,
                type: approvalType,
              })
            ),
          ...(additionalApproverUserIds ?? [])
            .filter((userId) => !additional_approver_user_ids?.includes(userId))
            .map((userId) =>
              remove<Approval>(
                `/api/approvals/${
                  approvals.find(
                    (a) => a.user_id === userId && a.type === approvalType
                  )!.id
                }`
              )
            ),
        ])) as Approval[]
      );
    } catch (err: any) {
      console.error(err);
      onSubmitError();
      throw err;
    }
  }

  function onSubmitError() {
    toast.error("Error updating Approvers");
  }

  function onSubmitSuccess(approvals: Approval[]) {
    toast.success("Updated Approvers");
    onOpenChange(false);
    mutate(`/api/opportunities/${opportunity.id}`);
  }

  return (
    <>
      {isSheet && (
        <ResourceHeader>
          <Flex align="start" direction="column" gap="3">
            <H4>{title}</H4>

            <Flex align="center" className={linkFlex()}>
              <Link href={`/clients/${client.id}`} variant="blue">
                <Text size="5">{client.organization_name}</Text>
              </Link>
            </Flex>
          </Flex>
        </ResourceHeader>
      )}

      <UpdateResourceForm
        {...{ hideForm, methods, onSubmit }}
        resource={RESOURCE}
      >
        <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
          <Column>
            <FormField
              label="Approvers"
              name="additional_approver_user_ids"
              required
            >
              <Controller
                control={methods.control}
                name="additional_approver_user_ids"
                render={({ field: { onChange, value: fieldValue } }) => {
                  return (
                    <Flex align="start" direction="column" gap="3">
                      {users
                        .filter((user) => {
                          const roleTitles = user.roles.map((r) => r.title);

                          return (
                            !roleTitles.includes(ApplicationRole.ACCOUNTING) &&
                            !roleTitles.includes(ApplicationRole.ADMIN)
                          );
                        })
                        .map(({ id, full_name }) => {
                          const isDisabled =
                            requiredApproverUserIds.includes(id);
                          const label = full_name;
                          const value = id;

                          function handleCheckedChange(checked: boolean) {
                            onChange(
                              checked
                                ? [...fieldValue, value]
                                : fieldValue.filter(
                                    (x: number | string) => x !== value
                                  )
                            );
                          }

                          return (
                            <Flex align="center" gap="5" key={value}>
                              <Checkbox
                                checked={
                                  isDisabled || fieldValue.includes(value)
                                }
                                disabled={isDisabled}
                                onCheckedChange={handleCheckedChange}
                                id={value}
                                size="2"
                              />
                              <label htmlFor={value}>
                                <Text size="4">{label}</Text>
                              </label>
                            </Flex>
                          );
                        })}
                    </Flex>
                  );
                }}
              />
            </FormField>
          </Column>
        </Flex>
      </UpdateResourceForm>
    </>
  );
}

export function UpdateApprovalRequests(props: { id: number }) {
  const { id: opportunityId } = props;
  const { data: opportunity } = useSWR<Opportunity>(
    `/api/opportunities/${opportunityId}`
  );
  const { data: requiredApprovers } = useSWR<RequiredApprover[]>(
    `/api/required-approvers?opportunity_id=${opportunityId}`
  );
  const { data: users } = useSWR<User[]>("/api/users");

  if (!opportunity || !requiredApprovers || !users) {
    return null;
  }

  return (
    <Form
      {...props}
      data={{
        opportunity,
        proposal: opportunity.proposals[0],
        requiredApprovers,
        users,
      }}
    />
  );
}
