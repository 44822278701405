import type { PropsWithChildren } from "react";

import { NavigationMenuContext, useNavigationMenuState } from "@/hooks";

export function NavigationMenuProvider(props: PropsWithChildren<{}>) {
  const navigationMenuState = useNavigationMenuState();
  return (
    <NavigationMenuContext.Provider value={navigationMenuState} {...props} />
  );
}
