import type { PropsWithChildren } from "react";

import { Box, css } from "@modulz/design-system";

import type { VariantProps } from "@modulz/design-system/dist/stitches.config";

const box = css({
  flexGrow: 1,
  variants: {
    flex: { true: { display: "flex", fd: "column", gap: "$5" } },
    size: { "1": { maxWidth: 335 }, "2": { maxWidth: 715 } },
  },
  defaultVariants: {
    size: "1",
  },
});

export function Column(props: PropsWithChildren<VariantProps<typeof box>>) {
  const { flex, size, ...rest } = props;
  return <Box {...rest} className={box({ flex, size })} />;
}
