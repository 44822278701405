import { useCallback } from "react";

import {
  ContactListItem,
  ResourceList,
  ResourceListEmptyState
} from "@/components";
import {
  useInfiniteContacts,
  useInfiniteList,
  useInitialContact
} from "@/hooks";

import type { Contact } from "@/types/models";

type Props = {
  clientId?: number;
  onSelected?: (contact: Contact) => void;
};

export function Contacts(props: Props) {
  const { clientId } = props;
  const initialContact = useInitialContact();
  const onSelected = props.onSelected ?? initialContact?.onSelected;
  const itemContent = useCallback(
    function itemContent(_, contact) {
      return <ContactListItem {...{ contact, onSelected }} />;
    },
    [onSelected]
  );
  const response = useInfiniteContacts({ clientId });
  const infiniteListProps = useInfiniteList<Contact>({
    itemContent,
    response,
  });

  return infiniteListProps.isEmpty ? (
    <ResourceListEmptyState>No Contacts</ResourceListEmptyState>
  ) : (
    <ResourceList<Contact> {...infiniteListProps} />
  );
}
