import { useResourceSearch } from "@/hooks";
import { textField } from "@/styles";
import { css, IconButton, TextField, Tooltip } from "@modulz/design-system";
import { Cross1Icon } from "@radix-ui/react-icons";

const form = css({
  alignSelf: "center",
  maxWidth: 430,
  position: "relative",
  width: "100%",
  "@bp1": { px: "$5" },
});
const iconButton = css({
  position: "absolute",
  right: "$2",
  top: "$2",
  "@bp1": { right: "$6" },
});

type Props = {
  placeholder: string;
};

export function ResourceSearchForm(props: Props) {
  const { placeholder } = props;
  const {
    methods,
    onClear,
    onClick,
    onSubmit,
    registration,
    textFieldRef,
    value,
  } = useResourceSearch();
  const { ref, ...rest } = registration;

  return (
    <form className={form()} onSubmit={methods.handleSubmit(onSubmit)}>
      <TextField
        {...rest}
        {...{ onClick, placeholder }}
        className={textField()}
        ref={(e) => {
          ref(e);

          if (e && textFieldRef) {
            textFieldRef.current = e;
          }
        }}
        size="2"
      />

      <Tooltip content="Clear search">
        <IconButton
          className={iconButton({
            css: { visibility: value ? "visible" : "hidden" },
          })}
          onClick={onClear}
        >
          <Cross1Icon color="gray" height={20} width={20} />
        </IconButton>
      </Tooltip>
    </form>
  );
}
