import { Link, Text } from "@/components";
import { useCacheResource } from "@/hooks";
import { Resource } from "@/types/resources";
import { Button, Flex } from "@modulz/design-system";

import type { Client } from "@/types/models";

type Props = { onSelected?: (client: Client) => void; client: Client };

export function ClientListItem(props: Props) {
  const { onSelected, client } = props;
  const {
    id,
    client_type: { title: clientTypeTitle },
    organization_name,
  } = client;
  const primaryTextEl = <Text size="4">{organization_name}</Text>;

  useCacheResource({ id, data: client, resource: Resource.CLIENTS });

  function handleSelected() {
    if (onSelected) {
      onSelected(client);
    }
  }

  return (
    <>
      <Flex align="start" direction="column" gap="1">
        {onSelected ? (
          primaryTextEl
        ) : (
          <Link href={`/clients/${id}`} variant="blue">
            {primaryTextEl}
          </Link>
        )}
        <Text variant="gray">{clientTypeTitle}</Text>
      </Flex>

      {onSelected && (
        <Button onClick={handleSelected} type="button" variant="blue">
          SELECT
        </Button>
      )}
    </>
  );
}
