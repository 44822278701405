import Router from "next/router";
import { useEffect } from "react";
import useSWR from "swr";

import { fetchJSON } from "@/lib/fetch-json";
import { ApplicationRole, User } from "@/types/models";

const fetcher = (url: string) =>
  fetchJSON(url).then((data) => {
    return { user: data?.user || null };
  });

export type UseUserParams = {
  redirectTo?: string;
  redirectIfFound?: boolean;
};

export function useUser({ redirectTo, redirectIfFound }: UseUserParams = {}) {
  const { data, error } = useSWR("/api/user", fetcher, {
    revalidateIfStale: true,
  });
  const user = data?.user;
  const finished = Boolean(data);
  const hasUser = Boolean(user);

  useEffect(() => {
    if (!redirectTo || !finished) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      Router.replace(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  return error ? null : user;
}

export function hasAccountingRole(user?: User | null) {
  return user?.roles.some((role) => role.title === ApplicationRole.ACCOUNTING);
}
