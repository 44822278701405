import { useRouter } from "next/router";

import { TabLink, Text } from "@/components";
import { css } from "@modulz/design-system";

const nav = css({ display: "flex", gap: "$1" });

type NavigationItem = {
  label: string;
  path: string;
};

type Props = {
  basePath: string;
  label: string;
  navigationItems: NavigationItem[];
};

export function NavigationTabs(props: Props) {
  const { basePath, label, navigationItems } = props;
  const { asPath, query } = useRouter();

  return (
    <nav
      aria-label={label}
      className={nav({
        css: { display: navigationItems.length > 1 ? "flex" : "none" },
      })}
    >
      {navigationItems.map(({ label, path }) => {
        const href = `${basePath}/${query.id}${path}`;

        return (
          <TabLink
            {...{ href }}
            active={asPath.split("?")[0] === href}
            key={path}
          >
            <Text>{label.toUpperCase()}</Text>
          </TabLink>
        );
      })}
    </nav>
  );
}
