import { Magic } from "magic-sdk";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { jsonContentTypeHeader } from "@/lib/fetch-json";

import type { Magic as TMagic } from "magic-sdk";

const MAGIC_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY;
let _magic: TMagic;

export type AuthProvider = "email";

export function useMagic() {
  const { mutate } = useSWRConfig();

  if (typeof window === "undefined") {
    return {};
  }

  if (!_magic) {
    if (!MAGIC_PUBLISHABLE_KEY) {
      throw new Error("Missing magic publishable key");
    }

    _magic = new Magic(MAGIC_PUBLISHABLE_KEY);
  }

  const magic = _magic;
  const handleLogin = useCallback(
    async function handleLogin(didToken: string, provider: AuthProvider) {
      const res = await fetch("/api/sign-in", {
        body: JSON.stringify({ provider }),
        headers: {
          ...jsonContentTypeHeader,
          Authorization: `Bearer ${didToken}`,
        },
        method: "POST",
      });

      if (res.status === 200) {
        mutate("/api/user");
      } else {
        throw new Error(await res.text());
      }
    },
    [mutate]
  );

  return { magic, onLogin: handleLogin };
}
