import type { PropsWithChildren } from "react";

import { InitialClientContext, useInitialClientState } from "@/hooks";

export function InitialClientProvider(
  props: PropsWithChildren<{ clientId?: number }>
) {
  const { clientId, ...rest } = props;
  const initialClientState = useInitialClientState(clientId);

  return <InitialClientContext.Provider {...rest} value={initialClientState} />;
}
