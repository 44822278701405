import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  Column,
  ContactField,
  DateTimePicker,
  Text,
  TextAreaField,
  UpdateResourceForm
} from "@/components";
import { useContactRelation, useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Box, css, Flex } from "@modulz/design-system";

import type { ClientContactReport } from "@/types/models";

const RESOURCE = Resource.CLIENT_CONTACT_REPORTS;
const box = css({ mb: "$5", width: "100%" });
const label = css({ mb: "$1" });

type FormValues = {
  contact_id: number;
  notes: string;
  occurred_at: string;
  opportunity_id?: number;
};

type Data = {
  ccr: ClientContactReport;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: { ccr },
    hideForm,
  } = props;
  const { contact_id, notes, occurred_at, opportunity, opportunity_id } = ccr;
  const defaultValues = {
    contact_id,
    notes,
    occurred_at,
    ...(opportunity_id && { opportunity_id }),
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, ClientContactReport>({
    defaultValues,
    methods,
    onSubmitSuccess: hideForm,
    resourceData: {
      endpoint: "/api/ccrs",
      resource: RESOURCE,
      singularName: "CCR",
    },
  });
  const contact = useContactRelation<FormValues>({
    control: methods.control,
    defaultValue: contact_id,
    fallbackData: ccr.contact,
  });

  return (
    <>
      <UpdateResourceForm
        {...{ hideForm, methods, onSubmit }}
        resource={RESOURCE}
      >
        <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
          <Column>
            <ContactField {...{ contact }} />

            {opportunity && (
              <Box className={box()}>
                <label className={label()}>
                  <Text size="4">Opportunity*</Text>
                </label>

                <Text>{opportunity?.title}</Text>
              </Box>
            )}
          </Column>

          <Column>
            <DateTimePicker disableFuture name="occurred_at" required />

            <TextAreaField
              autoFocus
              label="Notes"
              name="notes"
              placeholder="Contact reports positive developments"
              required
              rows={8}
            />
          </Column>
        </Flex>
      </UpdateResourceForm>
    </>
  );
}

export function UpdateCCR(props: { id: number; hideForm: () => void }) {
  const { data: ccr } = useSWR(`/api/ccrs/${props.id}`);

  if (!ccr) {
    return null;
  }

  return <Form {...props} data={{ ccr }} />;
}
