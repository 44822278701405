import {
  H2,
  ResourceHeader,
  ResourceSearchProvider,
  SearchDialog
} from "@/components";
import { useUser } from "@/hooks/use-user";
import { css, Flex } from "@modulz/design-system";

import type { PropsWithChildren, ReactNode } from "react";

const section = css({ display: "flex", jc: "center" });

type Props = {
  exportButton?: ReactNode;
  navMenu?: ReactNode;
  renderTitle?: (isAuthReady: boolean) => ReactNode;
  requireAuth?: boolean;
  search?: ReactNode;
  title?: string;
};

export function ResourcePage(props: PropsWithChildren<Props>) {
  const {
    children,
    exportButton = null,
    navMenu = null,
    renderTitle,
    requireAuth = true,
    search = null,
    title,
  } = props;
  const user = useUser();
  const isAuthReady = Boolean(!requireAuth || user);
  const articleEl = (
    <article>
      <ResourceHeader>
        <Flex align="center" gap="3" justify="between" wrap="wrap">
          {renderTitle ? (
            renderTitle(isAuthReady)
          ) : (
            <H2>{isAuthReady ? title : "..."}</H2>
          )}
          {isAuthReady ? exportButton : null}
          {isAuthReady ? navMenu : null}
        </Flex>
        {isAuthReady ? search : null}
      </ResourceHeader>

      {isAuthReady && <section className={section()}>{children}</section>}
    </article>
  );

  return search ? (
    <ResourceSearchProvider>
      {articleEl}
      <SearchDialog {...{ search }}>{children}</SearchDialog>
    </ResourceSearchProvider>
  ) : (
    articleEl
  );
}
