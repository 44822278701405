import { useEffect } from "react";
import { useSWRConfig } from "swr";

import type { Resource } from "@/types/resources";

export function useCacheResource<T>({
  id,
  data,
  resource,
}: {
  id: number | string;
  data: T;
  resource: Resource;
}) {
  const { mutate } = useSWRConfig();

  useEffect(() => {
    mutate(`/api/${resource}/${id}`, data, false);
  }, [data, id, mutate, resource]);
}
