import { FormProvider } from "react-hook-form";

import { UpdateSubmitButton } from "@/components";
import { Button, css, Flex } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type { UseFormReturn } from "react-hook-form";

import type { Resource } from "@/types/resources";

const form = css({ maxWidth: 1145, px: "$5", width: "100%" });

type Props = {
  hideForm: () => void;
  methods: UseFormReturn<any>;
  resource: Resource;
  onSubmit: (data: any) => void;
};

export function UpdateResourceForm(
  props: Omit<ComponentPropsWithoutRef<"form">, "onSubmit"> & Props
) {
  const { children, hideForm, methods, onSubmit } = props;

  return (
    <FormProvider {...methods}>
      <form className={form()} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}

        <Flex align="center" gap="5">
          <Button onClick={hideForm} size="2" tabIndex={-1} type="button">
            Cancel
          </Button>

          <UpdateSubmitButton />
        </Flex>
      </form>
    </FormProvider>
  );
}
