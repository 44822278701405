import { useState } from "react";
import { useForm } from "react-hook-form";

import { Text } from "@/components";
import { textField } from "@/styles";
import { Alert, Button, css, Flex, TextField } from "@modulz/design-system";

const subheading = css({ fontWeight: 500 });
const text = css({
  lineHeight: "17px",
  pl: "$2",
  "@bp2": { display: "none" },
});
const textWide = css({
  display: "none",
  "@bp2": { display: "block", lineHeight: "17px", pl: "$2" },
});
const button = css({
  fontSize: "$4!important",
  height: "$7!important",
  lineHeight: "$7!important",
});
const alert = css({ p: "$2" });
const alertText = css({ lineHeight: "17px" });

type FormValues = {
  email: string;
};

type Props = {
  isLoading: boolean;
  onSubmitSuccess: (email: string) => void;
};

export function SignInForm(props: Props) {
  const { isLoading, onSubmitSuccess } = props;
  const { formState, handleSubmit, register } = useForm<FormValues>({
    mode: "onChange",
  });
  const { errors, isSubmitting, isValid } = formState;
  const [errorMsg, setErrorMsg] = useState("");

  function onSubmit(data: FormValues) {
    try {
      onSubmitSuccess(data.email);
      setErrorMsg("");
    } catch (err: any) {
      console.error(err);
      setErrorMsg(err.message);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" direction="column" gap="2">
        <Text
          className={subheading()}
          size="5"
          variant="gray"
        >{`We'll email a magic link`}</Text>

        <Flex align="center" direction="column" gap="1">
          <Flex
            align="center"
            direction={{ "@initial": "column", "@bp2": "row" }}
            gap="3"
          >
            <TextField
              {...register("email", {
                required: true,
                setValueAs: (v) => v?.trim(),
              })}
              className={textField()}
              disabled={isSubmitting || isLoading}
              placeholder="email@soundearthinc.com"
              size="2"
              type="email"
            />

            {errors.email && (
              <Text className={text()} size="1" variant="red">
                Email is required
              </Text>
            )}

            <Button
              className={button()}
              disabled={isSubmitting || isLoading || !isValid}
              size="2"
              type="submit"
              variant="blue"
            >
              {isSubmitting || isLoading ? "Signing in..." : "Go"}
            </Button>
          </Flex>

          {errors.email && (
            <Text className={textWide()} size="1" variant="red">
              Email is required
            </Text>
          )}
        </Flex>

        <Alert
          className={alert({
            css: { visibility: errorMsg ? "visible" : "hidden" },
          })}
          size="1"
          variant="red"
        >
          <Text className={alertText()} size="2" variant="red">
            {errorMsg ? errorMsg : "..."}
          </Text>
        </Alert>
      </Flex>
    </form>
  );
}
