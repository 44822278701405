import { useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";

import { Column, TextField, UpdateResourceForm } from "@/components";
import { useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Proposal } from "@/types/models";

const RESOURCE = Resource.PROPOSALS;

type Data = {
  proposal: Proposal;
};

type FormValues = {
  accounting_number: string;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: { proposal },
    hideForm,
  } = props;
  const { id, accounting_number, opportunity } = proposal;
  const defaultValues = { accounting_number };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Proposal>({
    id,
    defaultValues,
    methods,
    onSubmitSuccess: handleSubmitSuccess,
    resourceData: {
      endpoint: "/api/proposals",
      resource: RESOURCE,
      singularName: "Proposal Number",
    },
  });
  const { mutate } = useSWRConfig();

  function handleSubmitSuccess() {
    hideForm();
    mutate(`/api/opportunities/${opportunity.id}`);
  }

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <TextField
            autoFocus
            label="Proposal number"
            name="accounting_number"
            placeholder="proposal-12345"
            required
          />
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateProposalNumber(props: {
  id: number;
  hideForm: () => void;
}) {
  const { data: opportunity } = useSWR(`/api/opportunities/${props.id}`);
  const { data: proposal } = useSWR(
    opportunity ? `/api/proposals/${opportunity.proposals[0].id}` : null
  );

  if (!proposal) {
    return null;
  }

  return <Form {...props} data={{ proposal }} />;
}
