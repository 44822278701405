import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { css, RadioGroup } from "@modulz/design-system";

import type { VariantProps } from "@modulz/design-system/dist/stitches.config";

import type { ComponentPropsWithoutRef } from "react";

const radioGroup = css({ display: "flex", fd: "column", gap: "$3" });

export function RadioGroupField(
  props: ComponentPropsWithoutRef<typeof RadioGroup> &
    VariantProps<typeof RadioGroup> & {
      label: string;
      name: string;
      valueAsNumber?: boolean;
    } & { description?: string }
) {
  const {
    children,
    description,
    label,
    name,
    required = false,
    valueAsNumber = false,
    ...rest
  } = props;
  const { control } = useFormContext();

  return (
    <FormField {...{ description, label, name, required }}>
      <Controller
        {...{ control, name }}
        render={({ field: { onChange, value } }) => {
          function handleValueChange(value: string) {
            onChange(valueAsNumber ? Number(value) : value);
          }

          return (
            <RadioGroup
              {...rest}
              className={radioGroup()}
              onValueChange={handleValueChange}
              value={value.toString()}
            >
              {children}
            </RadioGroup>
          );
        }}
        rules={{
          ...(required && { required: `${label} is required` }),
        }}
      />
    </FormField>
  );
}
