import { useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { useMedia, useSheet } from "@/hooks";
import { textField } from "@/styles";
import { config, Flex, TextField as DSTextField } from "@modulz/design-system";

import type { ComponentPropsWithoutRef, ReactNode } from "react";

export function TextField(
  props: ComponentPropsWithoutRef<typeof DSTextField> & {
    label: string;
    name: string;
    startAdornment?: ReactNode;
    valueAsNumber?: boolean;
  }
) {
  const {
    autoFocus,
    label,
    name,
    required = false,
    startAdornment = null,
    type,
    valueAsNumber = false,
    ...textFieldProps
  } = props;
  const { register } = useFormContext();
  const isSheet = Boolean(useSheet());
  const isWide = useMedia(config.media.bp1);

  return (
    <FormField {...{ label, name, required }}>
      <Flex align="center" css={{ width: "100%" }} gap="1">
        {startAdornment}
        <DSTextField
          {...textFieldProps}
          {...register(name, {
            ...(required && { required: `${label} is required` }),
            setValueAs: (v) => v?.trim(),
            ...((type === "number" || valueAsNumber) && {
              valueAsNumber: true,
            }),
          })}
          {...{ type }}
          autoFocus={Boolean(autoFocus && (isSheet || isWide))}
          className={textField()}
          size="2"
        />
      </Flex>
    </FormField>
  );
}
