import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  AddResourceForm,
  Column,
  ContactField,
  DateTimePicker,
  SelectInitialContact,
  Text,
  TextAreaField
} from "@/components";
import {
  useContactRelation,
  useInitialContact,
  useResourceForm
} from "@/hooks";
import { getQueryString } from "@/lib/fetch-json";
import { Resource } from "@/types/resources";
import { Box, css, Flex } from "@modulz/design-system";

import type {
  Client,
  ClientContactReport,
  Contact,
  Opportunity,
} from "@/types/models";

const RESOURCE = Resource.CLIENT_CONTACT_REPORTS;
const box = css({ mb: "$5", width: "100%" });
const label = css({ mb: "$1" });

type FormValues = {
  contact_id: number;
  notes: string;
  occurred_at: string;
  opportunity_id?: number;
};

type Data = {
  client?: Client;
  opportunity?: Opportunity;
};

function Form(props: { data: Data; initialContact: Contact }) {
  const {
    data: { client, opportunity },
    initialContact,
  } = props;
  const { id: initialContactId } = initialContact;
  const defaultValues = {
    contact_id: initialContactId,
    ...(opportunity && { opportunity_id: opportunity.id }),
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, ClientContactReport>({
    defaultValues,
    methods,
    resourceData: {
      endpoint: "/api/ccrs",
      resource: RESOURCE,
      singularName: "CCR",
    },
  });
  const contact = useContactRelation<FormValues>({
    control: methods.control,
    defaultValue: initialContactId,
    fallbackData: initialContact,
  });

  return (
    <>
      <AddResourceForm {...{ methods, onSubmit }}>
        <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
          <Column>
            <ContactField {...{ contact }} clientId={client?.id} />

            {opportunity && (
              <Box className={box()}>
                <label className={label()}>
                  <Text size="4">Opportunity*</Text>
                </label>

                <Text>{opportunity.title}</Text>
              </Box>
            )}
          </Column>

          <Column>
            <DateTimePicker disableFuture name="occurred_at" required />

            <TextAreaField
              autoFocus
              label="Notes"
              name="notes"
              placeholder="Contact reports positive developments"
              required
              rows={8}
            />
          </Column>
        </Flex>
      </AddResourceForm>
    </>
  );
}

export function AddCCR(props: { clientId?: number; opportunityId?: number }) {
  const { opportunityId } = props;
  const { data: opportunity } = useSWR<Opportunity>(
    opportunityId ? `/api/opportunities/${opportunityId}` : null
  );
  const clientId =
    props.clientId ?? (opportunity ? opportunity.client_id : undefined);
  const { data: client } = useSWR<Client>(
    clientId ? `/api/clients/${clientId}` : null
  );
  const { initialContact, initialContactId } = useInitialContact() ?? {};
  const { data: clientContacts } = useSWR<Contact[]>(
    !initialContactId && clientId
      ? `/api/contacts${getQueryString({
          client_id: clientId,
          is_default: true,
        })}`
      : null
  );
  const initialClientContact = clientContacts?.[0];

  if (
    (initialContactId && !initialContact) ||
    (clientId && !client) ||
    (opportunityId && !opportunity) ||
    (!initialContactId && clientId && !clientContacts)
  ) {
    return null;
  }

  const data = { client, opportunity };

  if (initialContact) {
    return <Form {...{ data, initialContact }} />;
  }

  if (initialClientContact) {
    return <Form {...{ data }} initialContact={initialClientContact} />;
  }

  return <SelectInitialContact {...{ clientId }} />;
}
