import { useFormContext } from "react-hook-form";

import { Button, css } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";

const button = css({ flexShrink: 1 });

export function SubmitButton(props: ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      {...props}
      className={button()}
      size="2"
      type="submit"
      variant="blue"
    />
  );
}

export function AddSubmitButton() {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();
  return <SubmitButton disabled={isSubmitting || !isValid}>Add</SubmitButton>;
}

export function UpdateSubmitButton() {
  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext();

  return (
    <SubmitButton disabled={!isDirty || isSubmitting || !isValid}>
      Update
    </SubmitButton>
  );
}
