import { NavigationTabs } from "@/components";

export function ContactNavigationTabs() {
  return (
    <NavigationTabs
      basePath="/contacts"
      label="Contact navigation"
      navigationItems={[
        { label: "Contact", path: "" },
        { label: "CCRs", path: "/ccrs" },
      ]}
    />
  );
}
