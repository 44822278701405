import { useForm } from "react-hook-form";
import useSWR from "swr";

import {
  ClientField,
  Column,
  PhoneTextField,
  SwitchField,
  TextField,
  UpdateResourceForm
} from "@/components";
import { useClientRelation, useResourceForm } from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { Contact } from "@/types/models";
import type { Id } from "@/types/resources";

const RESOURCE = Resource.CONTACTS;

type FormValues = {
  client_id: number;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  email: string;
  first_name: string;
  is_default: boolean;
  last_name: string;
  phone?: string;
  state?: string;
  title?: string;
  zip_code?: string;
};

type Data = {
  contact: Contact;
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: {
      contact: {
        id,
        client: initialClient,
        client_id,
        address_line_1,
        address_line_2,
        city,
        email,
        first_name,
        is_default,
        last_name,
        phone,
        state,
        title,
        zip_code,
      },
    },
    hideForm,
  } = props;
  const methods = useForm<FormValues>({
    defaultValues: {
      client_id,
      address_line_1,
      address_line_2,
      city,
      email,
      first_name,
      is_default,
      last_name,
      phone,
      state,
      title,
      zip_code,
    },
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, Contact>({
    id,
    methods,
    onSubmitSuccess: hideForm,
    resourceData: {
      endpoint: "/api/contacts",
      resource: RESOURCE,
      singularName: "Contact",
    },
  });
  const clientRelation = useClientRelation<FormValues>({
    control: methods.control,
    defaultValue: client_id,
    fallbackData: initialClient,
  });
  const client = clientRelation ?? initialClient;

  return (
    <UpdateResourceForm
      {...{ hideForm, methods, onSubmit }}
      resource={RESOURCE}
    >
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column>
          <ClientField {...{ client }} />

          <SwitchField
            label="Default client contact?"
            name="is_default"
            required
          />
        </Column>

        <Column>
          <TextField
            autoCapitalize="words"
            autoFocus
            label="First name"
            name="first_name"
            placeholder="Alex"
            required
          />

          <TextField
            autoCapitalize="words"
            label="Last name"
            name="last_name"
            placeholder="Smith"
            required
          />

          <TextField
            autoCapitalize="none"
            inputMode="email"
            label="Email"
            name="email"
            placeholder="email@example.com"
            required
          />

          <TextField label="Title" name="title" placeholder="CEO" />

          <PhoneTextField
            label="Phone"
            name="phone"
            placeholder="555-555-5555"
            type="tel"
          />
        </Column>

        <Column>
          <TextField
            autoCapitalize="words"
            label="Address line 1"
            name="address_line_1"
            placeholder="123 4th St."
          />

          <TextField
            autoCapitalize="words"
            label="Address line 2"
            name="address_line_2"
            placeholder="Suite 456"
          />

          <TextField
            autoCapitalize="words"
            label="City"
            name="city"
            placeholder="Seattle"
          />

          <TextField
            autoCapitalize="characters"
            label="State"
            name="state"
            placeholder="WA"
          />

          <TextField
            inputMode="numeric"
            label="Zip code"
            name="zip_code"
            placeholder="12345"
          />
        </Column>
      </Flex>
    </UpdateResourceForm>
  );
}

export function UpdateContact(props: { id: Id; hideForm: () => void }) {
  const { id, hideForm } = props;
  const { data: contact } = useSWR<Contact>(`/api/contacts/${id}`);

  if (!contact) {
    return null;
  }

  return <Form {...{ hideForm }} data={{ contact }} />;
}
