import type { PropsWithChildren } from "react";

import { InitialContactContext, useInitialContactState } from "@/hooks";

export function InitialContactProvider(
  props: PropsWithChildren<{ contactId?: number }>
) {
  const { contactId, ...rest } = props;
  const initialContactState = useInitialContactState(contactId);

  return (
    <InitialContactContext.Provider {...rest} value={initialContactState} />
  );
}
