import { pascalCase, snakeCase } from "change-case";
import IMask from "imask";

import type { ResourceFilters } from "@/types/resources";

export const isBrowser = typeof window !== "undefined";

export function mapKeys(obj: Record<string, any>, fn: (key: string) => string) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [fn(key), value])
  );
}

export function pascalKeys(obj: Record<string, any>) {
  return mapKeys(obj, pascalCase);
}

export function snakeKeys(obj: Record<string, any>) {
  return mapKeys(obj, snakeCase);
}

export function getValidFilters(filters: ResourceFilters) {
  return Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== undefined)
  );
}

export const phoneMasked = IMask.createMask({ mask: "000-000-0000" });
