import { useSheet } from "@/hooks";
import { css, keyframes, SheetContent } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";

const slideIn = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-200px)" },
});
const slideOut = keyframes({
  from: { transform: "translateX(-200px)" },
  to: { transform: "translateX(0)" },
});
const sheetContent = css({
  display: "flex",
  fd: "column",
  height: "100%",
  overflowY: "auto",
  pb: "$5",

  willChange: "transform",

  '&[data-expanded="expanded"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
    transform: "translateX(-200px)",
  },

  '&[data-expanded="normal"]': {
    animation: `${slideOut} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
    transform: "translateX(0)",
  },

  variants: {
    size: { "1": { maxWidth: 430, width: "100%" } },
  },

  defaultVariants: { size: "1" },
});

export function ResourceSheetContent(
  props: ComponentPropsWithoutRef<typeof SheetContent>
) {
  const sheet = useSheet();
  return (
    <SheetContent
      {...props}
      className={sheetContent()}
      ref={sheet.sheetContentRef}
    />
  );
}
