import { parseISO } from "date-fns/fp";

import { Link, Text } from "@/components";
import { useCacheResource } from "@/hooks";
import { Resource } from "@/types/resources";
import { Flex } from "@modulz/design-system";

import type { ClientContactReport } from "@/types/models";

type Props = {
  ccr: ClientContactReport;
};

export function CCRListItem(props: Props) {
  const { ccr } = props;
  const { id, contact, occurred_at } = ccr;
  const primaryTextEl = (
    <Text size="4">
      {parseISO(occurred_at).toLocaleString(undefined, {
        dateStyle: "medium",
        timeStyle: "short",
      })}
    </Text>
  );

  useCacheResource({
    id,
    data: ccr,
    resource: Resource.CLIENT_CONTACT_REPORTS,
  });

  return (
    <Flex align="start" direction="column" gap="1">
      <Link href={`/ccrs/${id}`} variant="blue">
        {primaryTextEl}
      </Link>
      <Text variant="gray">{contact.full_name}</Text>
    </Flex>
  );
}
