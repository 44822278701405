import { headingStyles, text } from "@/components/Text";
import { css } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type {
  CSS,
  VariantProps,
} from "@modulz/design-system/dist/stitches.config";

const h3 = css({
  ...headingStyles,
  defaultVariants: { ...headingStyles.defaultVariants, size: "2" },
});

export function H3(
  props: ComponentPropsWithoutRef<"h3"> &
    VariantProps<typeof text> & { css?: CSS }
) {
  const { className, css, gradient, variant, ...rest } = props;

  return <h3 {...rest} className={h3({ className, css, gradient, variant })} {...rest} />;
}
