import NextLink from "next/link";

import { css, Link as DSLink } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "@modulz/design-system/dist/stitches.config";
import type { LinkProps } from "next/link";

const link = css({
  variants: {
    fab: {
      true: {
        borderRadius: "$round",
        boxShadow:
          "0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)",
        display: "block",
        "@hover": {
          "&:hover": {
            boxShadow:
              "0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)",
          },
        },
        "&:focus": {
          borderColor: "$slate8",
          boxShadow:
            "0 0 0 1px $colors$indigo9, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)",
          outlineWidth: 0,
          outlineStyle: "none",
          outlineOffset: 0,
          textDecorationLine: "underline",
        },
        "&:active": {
          backgroundColor: "$slate4",
        },
      },
    },
    thick: {
      true: {
        textDecorationThickness: 2,
      },
    },
  },
});

export function Link(
  props: LinkProps &
    ComponentPropsWithoutRef<typeof DSLink> &
    VariantProps<typeof link>
) {
  const { children, css, fab, onClick, tabIndex, thick, variant, ...rest } =
    props;

  return (
    <NextLink {...rest} passHref>
      <DSLink
        {...{ onClick, tabIndex, variant }}
        className={link({ css, fab, thick })}
      >
        {children}
      </DSLink>
    </NextLink>
  );
}
