import { css } from "@modulz/design-system";

export const textField = css({
  fontSize: "$4!important",
  height: "$7!important",
  lineHeight: "$7!important",
  "&:-webkit-autofill::first-line": {
    fontSize: "$4!important",
  },
});
