import { ApprovalType, Decision } from "@/types/models";
import { Alert, css, Flex, Text } from "@modulz/design-system";
import {
  CheckCircledIcon,
  CrossCircledIcon,
  QuestionMarkCircledIcon
} from "@radix-ui/react-icons";

import type { PropsWithChildren, ReactNode } from "react";

const alert = css({ mb: 10, mt: 16, p: "$1", px: "$3", width: "100%" });

enum AlertVariant {
  BLUE = "blue",
  GREEN = "green",
  RED = "red",
}

const iconProps = { height: 25, width: 25 };
const alertsByDecision: Record<
  ApprovalType,
  Record<
    Decision,
    { iconEl: ReactNode; message: string; variant: AlertVariant }
  >
> = {
  [ApprovalType.GO_NO_GO]: {
    [Decision.APPROVED]: {
      iconEl: <CheckCircledIcon {...iconProps} color="green" />,
      message: "Go",
      variant: AlertVariant.GREEN,
    },
    [Decision.PENDING]: {
      iconEl: <QuestionMarkCircledIcon {...iconProps} color="blue" />,
      message: "Pending",
      variant: AlertVariant.BLUE,
    },
    [Decision.REJECTED]: {
      iconEl: <CrossCircledIcon {...iconProps} color="red" />,
      message: "No Go",
      variant: AlertVariant.RED,
    },
  },
  [ApprovalType.PROPOSAL]: {
    [Decision.APPROVED]: {
      iconEl: <CheckCircledIcon {...iconProps} color="green" />,
      message: "Approved",
      variant: AlertVariant.GREEN,
    },
    [Decision.PENDING]: {
      iconEl: <QuestionMarkCircledIcon {...iconProps} color="blue" />,
      message: "Pending",
      variant: AlertVariant.BLUE,
    },
    [Decision.REJECTED]: {
      iconEl: <CrossCircledIcon {...iconProps} color="red" />,
      message: "Not Approved",
      variant: AlertVariant.RED,
    },
  },
};

type Props = {
  decision: Decision;
  direction?: "column" | "row";
  type: ApprovalType;
};

export function DecisionAlert(props: PropsWithChildren<Props>) {
  const { children, decision, direction, type } = props;
  const isRowDirection = direction && direction === "row";
  const { iconEl, message, variant } = alertsByDecision[type][decision];

  return (
    <Alert {...{ variant }} className={alert()}>
      <Flex align="center" gap="2" justify={children ? "start" : "center"}>
        {iconEl}
        <Flex
          align={isRowDirection ? "center" : "start"}
          direction={direction ?? "column"}
          gap={isRowDirection ? "1" : "2"}
        >
          <Text size="4">{message}</Text>
          {children}
        </Flex>
      </Flex>
    </Alert>
  );
}
