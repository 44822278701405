import { useWatch } from "react-hook-form";
import useSWR from "swr";

import type { Control, FieldPath, Path, PathValue } from "react-hook-form";
import type { Client } from "@/types/models";

export function useClientRelation<T>({
  control,
  defaultValue,
  fallbackData,
  name = "client_id" as Path<T>,
}: {
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
  fallbackData?: Client;
  name?: FieldPath<T>;
}): Client | undefined {
  const clientId = useWatch<T>({ control, defaultValue, name });
  const { data: client } = useSWR(
    clientId ? `/api/clients/${clientId}` : null,
    { fallbackData }
  );

  return client;
}
