import { Text } from "@/components";
import { Flex } from "@modulz/design-system";

import type { ReactNode } from "react";

type Props = {
  primary?: ReactNode;
  secondary?: ReactNode;
};

export function ListItemText(props: Props) {
  const { primary, secondary } = props;

  return (
    <Flex direction="column" gap="1">
      {primary && <Text size="5">{primary}</Text>}

      {secondary && (
        <Text size="3" variant="gray">
          {secondary}
        </Text>
      )}
    </Flex>
  );
}
