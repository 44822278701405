import {
  ResourceSheet,
  ResourceSheetContent,
  UpdateApprovalRequests
} from "@/components";
import { IconButton, SheetTrigger } from "@modulz/design-system";
import { Pencil1Icon } from "@radix-ui/react-icons";

export function UpdateApprovalRequestsButton(props: { id: number }) {
  return (
    <ResourceSheet>
      <ResourceSheetContent>
        <UpdateApprovalRequests {...props} />
      </ResourceSheetContent>
      <SheetTrigger asChild>
        <IconButton>
          <Pencil1Icon height={20} width={20} />
        </IconButton>
      </SheetTrigger>
    </ResourceSheet>
  );
}
