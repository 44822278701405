import { formatISOWithOptions, isValid, parseISO } from "date-fns/fp";
import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import MUIDatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";

type Props = {
  label: string;
  name: string;
  required?: boolean;
};

export function DatePicker(props: Props) {
  const { name, label, required = false } = props;
  const { control } = useFormContext();

  return (
    <FormField {...{ label, name, required }}>
      <Controller
        {...{ control, name }}
        render={({ field: { onChange, value, ...rest } }) => {
          function handleChange(value: Date | null | undefined) {
            if (isValid(value)) {
              onChange(
                formatISOWithOptions({ representation: "date" }, value!)
              );
            }
          }

          return (
            <MUIDatePicker
              {...rest}
              inputFormat="MM/dd/yyyy"
              mask="__/__/____"
              onChange={handleChange}
              renderInput={(params) => (
                <TextField {...{ required }} {...params} margin="normal" />
              )}
              value={parseISO(value)}
            />
          );
        }}
      />
    </FormField>
  );
}
