import { useFormContext } from "react-hook-form";

import { FormField } from "@/components";
import { css, TextArea } from "@modulz/design-system";

import type { ComponentPropsWithoutRef } from "react";

export const textAreaField = css({
  fontSize: "$4!important",
  lineHeight: "$7!important",
  "&:-webkit-autofill::first-line": {
    fontSize: "$4!important",
  },
  p: "$2!important",
});

export function TextAreaField(
  props: ComponentPropsWithoutRef<typeof TextArea> & {
    label: string;
    name: string;
  }
) {
  const { label, name, required = false, rows = 8, ...rest } = props;
  const { register } = useFormContext();

  return (
    <FormField {...{ label, name, required }}>
      <TextArea
        {...register(name, {
          ...(required && { required: `${label} is required` }),
        })}
        {...rest}
        {...{ rows }}
        className={textAreaField()}
        size="2"
      />
    </FormField>
  );
}
