import { forwardRef, useCallback } from "react";
import { ItemContent, Virtuoso } from "react-virtuoso";

import { Text } from "@/components";
import { useResourceSearch, useSheet } from "@/hooks";
import { css, Flex, Skeleton } from "@modulz/design-system";

import type { ForwardedRef, PropsWithChildren } from "react";
import type { ItemProps, ListProps } from "react-virtuoso";
const text = css({
  mx: "$2",
  variants: { searchDialog: { true: { mt: "$3", mx: "$5", px: "$5" } } },
});
const flex = css({ height: 58, width: "100%" });
const skeleton = css({ width: "50%" });

export function ResourceListEmptyState(props: PropsWithChildren<{}>) {
  const { isDialogOpen } = useResourceSearch() ?? {};

  return (
    <Text
      className={text({ searchDialog: isDialogOpen })}
      variant="gray"
      {...props}
    />
  );
}

const footer = css({
  height: 0,
  variants: {
    searchDialog: { true: { height: 120 } },
    sheet: { true: { height: "$9" } },
  },
});

function Footer() {
  const isSheet = Boolean(useSheet());
  const { isDialogOpen } = useResourceSearch() ?? {};
  return (
    <div className={footer({ searchDialog: isDialogOpen, sheet: isSheet })} />
  );
}

const li = css({
  ai: "center",
  borderBottom: "1px solid $slate6",
  display: "flex",
  gap: "$3",
  jc: "space-between",
  p: "$2",
  width: "100%",
});

function Item(props: ItemProps) {
  return <li className={li()} {...props} />;
}

function LoadingItem() {
  return (
    <>
      <Flex className={flex()} direction="column" gap="2">
        <Skeleton variant="title" />
        <Skeleton className={skeleton()} variant="heading" />
      </Flex>
    </>
  );
}

const ul = css({
  maxWidth: 430,
  my: 0,
  px: "$5",
  width: "100%",
  variants: {
    searchDialog: {
      true: {
        px: 40,
      },
    },
    sheet: {
      true: {
        pl: 0,
        pr: "$3",
      },
    },
  },
});

const List = forwardRef<HTMLDivElement, ListProps>(
  ({ style, children }, ref) => {
    const { isDialogOpen } = useResourceSearch() ?? {};
    const isSheet = Boolean(useSheet());

    return (
      <ul
        className={ul({ searchDialog: isDialogOpen, sheet: isSheet })}
        {...{ style, children }}
        ref={ref as ForwardedRef<HTMLUListElement>}
      />
    );
  }
);

const style = {
  flexGrow: 1,
  height: "100%",
  marginBottom: 0,
  maxWidth: 430,
  marginTop: 0,
  width: "100%",
  zIndex: 997,
};
const sheetStyle = {
  alignSelf: "center",
  maxWidth: 380,
  marginLeft: 25,
  marginRight: 25,
};

export function ResourceList<T>(props: {
  data: T[];
  endReached: (index: number) => void;
  initialScrollTop?: number;
  isLoadingInitialData: boolean;
  isScrolling: (isScrolling: boolean) => void;
  isSheet: boolean;
  itemContent: ItemContent<T>;
  useWindowScroll: boolean;
}) {
  const {
    data,
    endReached,
    isLoadingInitialData,
    isScrolling,
    isSheet,
    itemContent,
    useWindowScroll,
  } = props;
  const loadingItemContent = useCallback(function () {
    return <LoadingItem />;
  }, []);
  const commonProps = {
    components: { Footer, Item, List, ScrollSeekPlaceholder: LoadingItem },
    overscan: 200,
    style: { ...style, ...(isSheet && sheetStyle) },
    useWindowScroll,
  };

  return isLoadingInitialData ? (
    <Virtuoso
      {...{ endReached }}
      {...commonProps}
      data={Array.from(new Array(10).keys()).map(() => ({} as T))}
      itemContent={loadingItemContent}
      key="loading"
    />
  ) : (
    <Virtuoso
      {...{
        data,
        endReached,
        isScrolling,
        itemContent,
      }}
      {...commonProps}
      key="loaded"
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > 50,
        exit: (velocity) => Math.abs(velocity) < 10,
      }}
    />
  );
}
