import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

import { useResourceSearch, useSheet } from "@/hooks";

import type { ItemContent } from "react-virtuoso";
import type { SWRInfiniteResponse } from "swr/infinite";

export function useInfiniteList<T>({
  itemContent,
  response,
}: {
  itemContent: ItemContent<T>;
  response: SWRInfiniteResponse<T[]> & {
    isEmpty: boolean;
    isReachingEnd: boolean | undefined;
    nextPage: () => Promise<T[][] | undefined>;
    refresh: () => void;
  };
}) {
  const { data, error, isEmpty, isReachingEnd, isValidating, nextPage, size } =
    response;
  const [lastIndexReached, setLastIndexReached] = useState<number>(0);
  const items = data ? data.flat() : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  const isRefreshing = isValidating && data && data.length === size;
  const fetchedCount = items.length;
  const handleEndReached = useCallback(
    function handleEndReached(index: number) {
      if (index > lastIndexReached) {
        setLastIndexReached(index);
      }
    },
    [lastIndexReached]
  );
  const router = useRouter();
  const {
    query: { st },
  } = router;
  const initialScrollTop = useRef(st ? Number(st) : undefined);
  const { isDialogOpen } = useResourceSearch() ?? {};
  const isSheet = Boolean(useSheet());
  const useWindowScroll = Boolean(!isDialogOpen && !isSheet);
  const handleScrolling = useCallback(
    function handleScrolling(isScrolling: boolean) {
      if (!isScrolling && useWindowScroll) {
        window.requestIdleCallback(() => {
          setTimeout(() => {
            router.replace(
              {
                query: {
                  ...router.query,
                  st: window.scrollY,
                },
              },
              undefined,
              { shallow: true }
            );
          }, 64);
        });
      }
    },
    [router, useWindowScroll]
  );

  useEffect(() => {
    if (
      initialScrollTop.current !== undefined &&
      useWindowScroll &&
      !isLoadingInitialData
    ) {
      setTimeout(() => {
        window.scrollTo({ top: Number(initialScrollTop.current) });
      }, 200);
    }
  }, [initialScrollTop, isLoadingInitialData, useWindowScroll]);

  useEffect(() => {
    if (
      lastIndexReached === fetchedCount - 1 &&
      !isReachingEnd &&
      !isLoadingMore &&
      !isRefreshing
    ) {
      nextPage();
    }
  }, [
    fetchedCount,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    lastIndexReached,
    nextPage,
  ]);

  useEffect(() => {
    const { pages } = router.query;

    if (useWindowScroll && (!pages || Number(pages) !== size)) {
      router.replace(
        {
          query: {
            ...router.query,
            pages: size,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router, size, useWindowScroll]);

  return {
    data: items,
    endReached: handleEndReached,
    isEmpty,
    isLoadingInitialData,
    isScrolling: handleScrolling,
    isSheet,
    itemContent,
    useWindowScroll,
  };
}
