import { Text } from "@/components";
import {
  Accordion as DSAccordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  css
} from "@modulz/design-system";

import type { PropsWithChildren } from "react";

const accordion = css({ mb: "$5", width: 300 });
const accordionContent = css({ p: "$5" });
const strong = css({ fontWeight: 500 });

export function Accordion(props: PropsWithChildren<{ label: string }>) {
  const { children, label } = props;

  return (
    <DSAccordion className={accordion()} type="single">
      <AccordionItem value="item-1">
        <AccordionTrigger type="button">
          <Text className={strong()} size="3">
            {label}
          </Text>
        </AccordionTrigger>
        <AccordionContent className={accordionContent()}>
          {children}
        </AccordionContent>
      </AccordionItem>
    </DSAccordion>
  );
}
