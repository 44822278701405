import { LinearProgress } from "@/components";
import { css, Flex } from "@modulz/design-system";

import type { PropsWithChildren } from "react";
const header = css({
  backgroundColor: "$loContrast",
  borderBottom: "1px solid $slate6",
  display: "flex",
  jc: "center",
  position: "relative",
  py: "$4",
  zIndex: 1000,

  "@bp3": {
    position: "sticky",
    top: 0,
  },
});
const flex = css({ flexGrow: 1, maxWidth: 1145, px: "$5" });

export function Header(props: PropsWithChildren<{}>) {
  return (
    <header className={header()}>
      <Flex align="center" className={flex()} justify="between">
        {props.children}
      </Flex>

      <LinearProgress />
    </header>
  );
}
