import useSWRInfinite from "swr/infinite";

import { useInitialInfiniteSize, useResourceSearch } from "@/hooks";
import { getQueryString } from "@/lib/fetch-json";
import { getValidFilters, snakeKeys } from "@/lib/utils";

import type { Opportunity } from "@/types/models";
import type { ResourceFilters } from "@/types/resources";

const PAGE_SIZE = 100;

export function useInfiniteOpportunities(filters: ResourceFilters) {
  const validFilters = getValidFilters(filters);
  const { value } = useResourceSearch() ?? {};
  const initialSize = useInitialInfiniteSize();
  const { data, error, isValidating, mutate, setSize, size } = useSWRInfinite<
    Opportunity[]
  >(
    (index) =>
      `/api/opportunities${getQueryString({
        $limit: PAGE_SIZE,
        $skip: index * PAGE_SIZE,
        ...(validFilters && snakeKeys(validFilters)),
        ...(value && { title: { $like: `%${value}%` } }),
      })}`,
    { initialSize, revalidateIfStale: true }
  );
  const isEmpty = data?.[0]?.length === 0;

  return {
    data,
    error,
    isEmpty,
    isReachingEnd:
      isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE),
    isValidating,
    mutate,
    nextPage: () => setSize(size + 1),
    refresh: mutate,
    setSize,
    size,
  };
}
