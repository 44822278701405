import { createContext, useCallback, useContext, useState } from "react";
import useSWR from "swr";

import type { Contact } from "@/types/models";

export type TInitialContactContext = {
  initialContactId?: number;
  initialContact?: Contact;
  onSelected: (contact: Contact) => void;
};

export const InitialContactContext =
  createContext<TInitialContactContext | null>(null as any);

export function useInitialContactState(contactId?: number) {
  const [initialContactId, setInitialContactId] = useState<number | undefined>(
    contactId
  );
  const { data: initialContact } = useSWR<Contact>(
    initialContactId ? `/api/contacts/${initialContactId}` : null
  );
  const handleContactSelected = useCallback(
    function (contact: Contact) {
      setInitialContactId(contact.id);
      window.scrollTo({ top: 0 });
    },
    [setInitialContactId]
  );

  return {
    initialContact,
    initialContactId,
    onSelected: handleContactSelected,
  };
}

export function useInitialContact() {
  return useContext(InitialContactContext);
}
