import { NavigationTabs } from "@/components";

export function ClientNavigationTabs() {
  return (
    <NavigationTabs
      basePath="/clients"
      label="Client navigation"
      navigationItems={[
        { label: "Client", path: "" },
        { label: "Opportunities", path: "/opportunities" },
        { label: "Contacts", path: "/contacts" },
        { label: "CCRs", path: "/ccrs" },
      ]}
    />
  );
}
