import { useCallback, useEffect, useRef, useState } from "react";

import { useThrottleFn } from "@react-cmpt/use-throttle";

const SAFARI_MOBILE_BOTTOM_MENU_HEIGHT = 44;

export function useFixedBottom(offset: number) {
  const [bottom, setBottom] = useState<number>(offset);
  const anchorRef = useRef<HTMLDivElement>(null as any);
  const { callback, cancel } = useThrottleFn((): void => {
    if (!anchorRef.current) {
      return;
    }

    const { bottom } = anchorRef.current!.getBoundingClientRect();

    if (Math.floor(bottom) > window.innerHeight) {
      setBottom(offset + SAFARI_MOBILE_BOTTOM_MENU_HEIGHT);
    } else {
      setBottom(offset);
    }
  }, 16);
  const handleScroll = useCallback(
    function () {
      window.requestIdleCallback(callback);
    },
    [callback]
  );

  useEffect(() => {
    handleScroll();
    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      cancel();
      document.removeEventListener("scroll", handleScroll);
    };
  }, [cancel, handleScroll]);

  return { bottom, ref: anchorRef };
}
