import { FormProvider } from "react-hook-form";

import { AddResourceFormActions } from "@/components";
import { css } from "@modulz/design-system";

import type { PropsWithChildren } from "react";
import type { UseFormReturn } from "react-hook-form";

const form = css({ maxWidth: 1145, px: "$5", width: "100%" });

type Props = {
  defaultActions?: boolean;
  methods: UseFormReturn<any>;
  onSubmit: (data: any) => void;
};

export function AddResourceForm(props: PropsWithChildren<Props>) {
  const { children, defaultActions = true, methods, onSubmit } = props;

  return (
    <FormProvider {...methods}>
      <form className={form()} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}

        {defaultActions && <AddResourceFormActions />}
      </form>
    </FormProvider>
  );
}
