import {
  AddContactButton,
  Contacts,
  H4,
  ResourceSearchForm,
  ResourceSearchProvider,
  SearchDialog
} from "@/components";
import { useSheet } from "@/hooks";
import { Box, css } from "@modulz/design-system";

const box = css({ maxWidth: 430, width: "100%" });
const header = css({
  bc: "$loContrast",
  display: "flex",
  fd: "column",
  gap: "$4",
  pb: "$5",
  px: "$5",

  "@bp1": {
    position: "sticky",
    px: 0,
    top: 163,
    zIndex: 1000,
  },

  variants: {
    sheet: {
      true: {
        "@bp1": {
          position: "static",
          top: "initial",
          zIndex: "initial",
        },
      },
    },
  },
});

export function SelectInitialContact(props: { clientId?: number }) {
  const isSheet = Boolean(useSheet());
  const searchEl = <ResourceSearchForm placeholder="Search Contacts..." />;
  const contactsEl = <Contacts {...props} />;

  return (
    <ResourceSearchProvider>
      <Box className={box()}>
        <header className={header({ sheet: isSheet })}>
          <H4>Select Contact</H4>
          {searchEl}
          <AddContactButton {...props} />
        </header>

        {contactsEl}
      </Box>
      <SearchDialog search={searchEl}>{contactsEl}</SearchDialog>
    </ResourceSearchProvider>
  );
}
