import { useCallback } from "react";

import {
  CCRListItem,
  ResourceList,
  ResourceListEmptyState
} from "@/components";
import { useInfiniteCCRs, useInfiniteList } from "@/hooks";
import { Flex } from "@modulz/design-system";

import type { ClientContactReport } from "@/types/models";

type Props = {
  clientId?: number;
  contactId?: number;
  opportunityId?: number;
};

export function CCRs(props: Props) {
  const { clientId, contactId, opportunityId } = props;
  const itemContent = useCallback(function itemContent(_, ccr) {
    return <CCRListItem {...{ ccr }} />;
  }, []);
  const response = useInfiniteCCRs({ clientId, contactId, opportunityId });
  const infiniteListProps = useInfiniteList<ClientContactReport>({
    itemContent,
    response,
  });

  return infiniteListProps.isEmpty ? (
    <Flex direction="column" gap="5">
      <ResourceListEmptyState>No CCRs</ResourceListEmptyState>
    </Flex>
  ) : (
    <ResourceList<ClientContactReport> {...infiniteListProps} />
  );
}
