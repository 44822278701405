import { Controller, useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";

import {
  Accordion,
  AddResourceForm,
  AddSubmitButton,
  Column,
  FormField,
  Text
} from "@/components";
import { useResourceForm } from "@/hooks/use-resource-form";
import {
  ApplicationRole,
  ApprovalRequest,
  ApprovalType,
  Opportunity,
  RequiredApprover,
  User
} from "@/types/models";
import { Resource } from "@/types/resources";
import { Button, Checkbox, css, Flex } from "@modulz/design-system";

const RESOURCE = Resource.APPROVAL_REQUESTS;
const flex = css({ mt: "$5" });

type ApprovalRequestWithId = ApprovalRequest & { id: number };

type FormValues = {
  additional_approver_user_ids: string[];
  opportunity_id: number;
  type: ApprovalType;
};

type Data = {
  opportunity: Opportunity;
  requiredApprovers: RequiredApprover[];
  users: User[];
};

function Form(props: { data: Data; hideForm: () => void }) {
  const {
    data: { opportunity, requiredApprovers, users },
    hideForm,
  } = props;
  const { id: opportunity_id, proposals } = opportunity;
  const requiredApproverUserIds = requiredApprovers.map((ra) => ra.user_id);
  const approvalType = proposals[0]?.id
    ? ApprovalType.PROPOSAL
    : ApprovalType.GO_NO_GO;
  const defaultValues = {
    additional_approver_user_ids: [],
    opportunity_id,
    type: approvalType,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { onSubmit } = useResourceForm<FormValues, ApprovalRequestWithId>({
    defaultValues,
    methods,
    onSubmitSuccess: handleSubmitSuccess,
    resourceData: {
      endpoint: "/api/approval-requests",
      resource: RESOURCE,
      singularName: "Approval Request",
    },
  });
  const { mutate } = useSWRConfig();

  function handleSubmitSuccess() {
    hideForm();
    mutate(`/api/opportunities/${opportunity_id}`);
  }

  return (
    <AddResourceForm {...{ methods, onSubmit }} defaultActions={false}>
      <Flex gap={{ "@initial": "1", "@bp1": "7" }} wrap="wrap">
        <Column flex>
          <Accordion label="Add Approvers">
            <FormField
              label="Approvers"
              name="additional_approver_user_ids"
              required
            >
              <Controller
                control={methods.control}
                name="additional_approver_user_ids"
                render={({ field: { onChange, value: fieldValue } }) => {
                  return (
                    <Flex align="start" direction="column" gap="3">
                      {users
                        .filter((user) => {
                          const roleTitles = user.roles.map((r) => r.title);

                          return (
                            !roleTitles.includes(ApplicationRole.ACCOUNTING) &&
                            !roleTitles.includes(ApplicationRole.ADMIN)
                          );
                        })
                        .map(({ id, full_name }) => {
                          const isDisabled =
                            requiredApproverUserIds.includes(id);
                          const label = full_name;
                          const value = id;

                          function handleCheckedChange(checked: boolean) {
                            onChange(
                              checked
                                ? [...fieldValue, value]
                                : fieldValue.filter(
                                    (x: number | string) => x !== value
                                  )
                            );
                          }

                          return (
                            <Flex align="center" gap="5" key={value}>
                              <Checkbox
                                checked={
                                  isDisabled || fieldValue.includes(value)
                                }
                                disabled={isDisabled}
                                onCheckedChange={handleCheckedChange}
                                id={value}
                                size="2"
                              />
                              <label htmlFor={value}>
                                <Text size="4">{label}</Text>
                              </label>
                            </Flex>
                          );
                        })}
                    </Flex>
                  );
                }}
              />
            </FormField>
          </Accordion>
        </Column>
      </Flex>

      <Flex align="center" className={flex()} gap="5">
        <Button onClick={hideForm} size="2" tabIndex={-1} type="button">
          Cancel
        </Button>

        <AddSubmitButton />
      </Flex>
    </AddResourceForm>
  );
}

export function AddApprovalRequest(props: {
  id: number;
  hideForm: () => void;
}) {
  const { id: opportunityId } = props;
  const { data: opportunity } = useSWR(`/api/opportunities/${opportunityId}`);
  const { data: requiredApprovers } = useSWR<RequiredApprover[]>(
    `/api/required-approvers?opportunity_id=${opportunityId}`
  );
  const { data: users } = useSWR<User[]>("/api/users");

  if (!opportunity || !requiredApprovers || !users) {
    return null;
  }

  return <Form {...props} data={{ opportunity, requiredApprovers, users }} />;
}
