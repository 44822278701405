import { useFormState } from "react-hook-form";

import { Text } from "@/components";
import { css, Flex } from "@modulz/design-system";

import type { PropsWithChildren } from "react";
const label = css({
  display: "flex",
  fd: "column",
  gap: "$1",
  position: "relative",
  width: "100%",
});
const div = css({ mb: "$3" });

type Props = {
  description?: string;
  label: string;
  name: string;
  required?: boolean;
  selectable?: boolean;
};

export function FormField(props: PropsWithChildren<Props>) {
  const {
    children,
    description = null,
    label: labelText,
    name,
    required = false,
    selectable = true,
  } = props;
  const { errors } = useFormState();
  const error = errors?.[name];
  const textEl = (
    <Text size="4">
      {labelText}
      {required ? "*" : ""}
    </Text>
  );
  const errorEl = (
    <div className={div()}>
      <Text css={{ visibility: error ? "visible" : "hidden" }} variant="red">
        {error?.message ?? "..."}
      </Text>
    </div>
  );

  return selectable ? (
    <label className={label()}>
      {textEl}
      {description}
      {children}
      {errorEl}
    </label>
  ) : (
    <Flex direction="column" gap="1">
      <label>{textEl}</label>
      {description}
      {children}
      {errorEl}
    </Flex>
  );
}
