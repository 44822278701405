import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react";
import { useForm, useWatch } from "react-hook-form";

import { useDebounce, useMedia, useSheet } from "@/hooks";
import { config } from "@modulz/design-system";

import type { MutableRefObject } from "react";
import type { UseFormRegisterReturn, UseFormReturn } from "react-hook-form";

type FormValues = {
  search?: string;
};

type TResourceSearchContext = {
  isDialogOpen: boolean;
  methods: UseFormReturn<FormValues>;
  onClear: () => void;
  onClick: () => void;
  onSubmit: () => void;
  registration: UseFormRegisterReturn;
  textFieldRef: MutableRefObject<HTMLInputElement>;
  value: string;
};

export const ResourceSearchContext = createContext<TResourceSearchContext>(
  null as any
);

export function useResourceSearchState() {
  const router = useRouter();
  const { asPath } = router;
  const isDialogOpen = asPath.endsWith("#search");
  const isSheet = useSheet();
  const isWide = useMedia(config.media.bp1);
  const methods = useForm<FormValues>({ defaultValues: { search: "" } });
  const { control, register, reset } = methods;
  const registration = register("search", { setValueAs: (v) => v?.trim() });
  const watchSearch = useWatch({ control, defaultValue: "", name: "search" });
  const value = useDebounce<string>(watchSearch ?? "", 500);
  const textFieldRef = useRef<HTMLInputElement>(null as any);
  const onClear = useCallback(
    function handleClear() {
      reset();
      textFieldRef?.current?.focus();
    },
    [textFieldRef]
  );
  const onClick = useCallback(
    function onClick() {
      if (!isWide && !isDialogOpen) {
        router.push(`${asPath}#search`);
      }
    },
    [isDialogOpen, isWide]
  );
  const onSubmit = useCallback(
    function onSubmit() {
      textFieldRef?.current?.blur();
    },
    [textFieldRef]
  );

  useEffect(() => {
    if (isDialogOpen || isSheet || isWide) {
      setTimeout(() => {
        textFieldRef?.current?.focus();
      });
    }
  }, [isDialogOpen, isSheet, isWide, textFieldRef]);

  return {
    isDialogOpen,
    methods,
    onClear,
    onClick,
    onSubmit,
    registration,
    textFieldRef,
    value,
  };
}

export function useResourceSearch() {
  return useContext(ResourceSearchContext);
}
