import { Link, Text } from "@/components";
import { useCacheResource, useResourceSearch } from "@/hooks";
import { Resource } from "@/types/resources";
import { Button, Flex } from "@modulz/design-system";

import type { Opportunity } from "@/types/models";

type Props = {
  onSelected?: (opportunity: Opportunity) => void;
  opportunity: Opportunity;
};

export function OpportunityListItem(props: Props) {
  const { onSelected, opportunity } = props;
  const {
    id,
    client: { id: clientId, organization_name: clientOrganizationName },
    title,
  } = opportunity;
  const primaryTextEl = <Text size="4">{title}</Text>;
  const { isDialogOpen } = useResourceSearch() ?? {};

  useCacheResource({ id, data: opportunity, resource: Resource.OPPORTUNITIES });
  useCacheResource({
    id: clientId,
    data: opportunity.client,
    resource: Resource.CLIENTS,
  });

  function handleSelected() {
    if (onSelected) {
      onSelected(opportunity);
    }
  }

  return (
    <>
      <Flex align="start" direction="column" gap="1">
        {onSelected ? (
          primaryTextEl
        ) : (
          <Link
            href={`/opportunities/${id}`}
            replace={isDialogOpen}
            variant="blue"
          >
            {primaryTextEl}
          </Link>
        )}
        <Text variant="gray">{clientOrganizationName}</Text>
      </Flex>

      {onSelected && (
        <Button onClick={handleSelected} type="button" variant="blue">
          SELECT
        </Button>
      )}
    </>
  );
}
