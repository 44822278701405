import Router from "next/router";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useInfiniteClients, useResourceSearch, useSheet } from "@/hooks";
import { onSubmitAdd, onSubmitUpdate } from "@/lib/forms";

import type { Id, ResourceData } from "@/types/resources";

import type { UseFormReturn } from "react-hook-form";

export function useResourceForm<T, U extends { id: Id }>({
  id,
  defaultValues,
  methods,
  onSubmitSuccess,
  resourceData,
}: {
  id?: Id;
  defaultValues?: Partial<T>;
  methods: UseFormReturn<T, object>;
  onSubmitSuccess?: (data: U) => void;
  resourceData: ResourceData;
}) {
  const { resource } = resourceData;
  const {
    formState: { dirtyFields },
  } = methods;
  const { refresh } = useInfiniteClients();
  const { onOpenChange } = useSheet() ?? {};
  const { isDialogOpen } = useResourceSearch() ?? {};
  const { mutate } = useSWRConfig();
  const onSubmit = useCallback(
    async function onSubmit(data: T) {
      if (id) {
        const updated = await onSubmitUpdate<T, U>({
          id,
          data,
          dirtyFields,
          mutate,
          resourceData,
        });

        mutate(`/api/${resource}/${id}`, updated, false);

        if (onSubmitSuccess) {
          onSubmitSuccess(updated);
        } else {
          if (onOpenChange) {
            onOpenChange(false);
          }
        }

        return updated;
      } else {
        const added = await onSubmitAdd<T, U>({
          data,
          defaultValues,
          dirtyFields,
          mutate,
          resourceData,
        });

        mutate(`/api/${resource}/${added.id}`, added, false);
        refresh();

        if (isDialogOpen) {
          Router.back();
        }

        if (onSubmitSuccess) {
          onSubmitSuccess(added);
        } else {
          if (onOpenChange) {
            onOpenChange(false);
          } else {
            Router.replace(`/${resource}/${added.id}`);
          }
        }

        return added;
      }
    },
    [id, defaultValues, dirtyFields, mutate, onOpenChange, resource]
  );

  return { methods, onSubmit };
}
