import { useRouter } from "next/router";
import useSWR from "swr";

import { useProtectedRoute } from "@/hooks";

import type { Contact } from "@/types/models";

export function useContact() {
  const user = useProtectedRoute();
  const router = useRouter();
  const {
    asPath,
    isReady,
    query: { id, contact_id },
  } = router;
  const contactId = id ?? contact_id;
  const { data: contact, mutate } = useSWR<Contact>(
    user && isReady && contactId ? `/api/contacts/${contactId}` : null
  );

  return {
    asPath,
    contact,
    isLoading: !isReady || (isReady && contactId && !contact),
    mutate,
  };
}
