import { useCallback, useEffect, useState } from "react";

import { AFTER_FETCH_EVENT, BEFORE_FETCH_EVENT } from "@/lib/fetch-json";
import { css, keyframes } from "@modulz/design-system";

const indeterminate1 = keyframes({
  "0%": {
    left: "-35%",
    right: "100%",
  },
  "60%": {
    left: "100%",
    right: "-90%",
  },
  "100%": {
    left: "100%",
    right: "-90%",
  },
});
const indeterminate2 = keyframes({
  "0%": {
    left: "-200%",
    right: "100%",
  },
  "60%": {
    left: "107%",
    right: "-8%",
  },
  "100%": {
    left: "107%",
    right: "-8%",
  },
});
const root = css({
  backgroundColor: "$blue4",
  bottom: 0,
  height: 2,
  left: 0,
  position: "absolute",
  right: 0,
  overflow: "hidden",
  display: "block",
  width: "100%",
  zIndex: 1001,
});
const linearProgressBar1 = css({
  animation: `${indeterminate1} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite`,
  backgroundColor: "$blue11",
  width: "auto",
  position: "absolute",
  left: 0,
  bottom: 0,
  top: 0,
  transition: "transform 0.2s linear",
  transformOrigin: "left",
});
const linearProgressBar2 = css({
  animation: `${indeterminate2} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite`,
  backgroundColor: "$blue11",
  width: "auto",
  position: "absolute",
  left: 0,
  bottom: 0,
  top: 0,
  transition: "transform 0.2s linear",
  transformOrigin: "left",
});

function LinearProgressBar1() {
  return <span className={linearProgressBar1()} />;
}

function LinearProgressBar2() {
  return <span className={linearProgressBar2()} />;
}

export function LinearProgress() {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const handleBeforeFetch = useCallback(
    function handleBeforeFetch() {
      setIsFetching(true);
    },
    [setIsFetching]
  );
  const handleAfterFetch = useCallback(
    function handleAfterFetch() {
      setIsFetching(false);
    },
    [setIsFetching]
  );

  useEffect(() => {
    document.addEventListener(BEFORE_FETCH_EVENT, handleBeforeFetch);
    document.addEventListener(AFTER_FETCH_EVENT, handleAfterFetch);

    return () => {
      document.removeEventListener(BEFORE_FETCH_EVENT, handleBeforeFetch);
      document.removeEventListener(AFTER_FETCH_EVENT, handleAfterFetch);
    };
  }, [handleAfterFetch, handleBeforeFetch]);

  return (
    <span
      className={root({
        css: { backgroundColor: isFetching ? "$blue4" : "$loContrast" },
      })}
      role="progressBar"
    >
      {isFetching && (
        <>
          <LinearProgressBar1 />
          <LinearProgressBar2 />
        </>
      )}
    </span>
  );
}
